import styled from "styled-components";
import { filter, find, get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { GET_ME, MY_CONTRACTS, UPDATE_USER } from "../../query/userQuery";
import { useMutation, useQuery } from "@apollo/client";
import {
    isAvailableContract,
    isMatchedContract,
} from "../../toolbox/calculate";
import { asyncSendTelegram, commaNum, useForm } from "../../toolbox";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { SERVER_ADDRESS, TOSS_SUB_CLIENT_KEY } from "../../index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { filterAvailableWallets, getLoginLink } from "../../toolbox/format";
import { UserEditModal } from "./component/UserEdit";
import { Alert, Dialog, Snackbar } from "@mui/material";
import { ProfileEditModal } from "./component/ProfileEdit";
import { loadingAction } from "../../redux/loadingReducer";
import axios from "axios";
import { authAction } from "../../redux/authReducer";
import { flatEntity } from "../../toolbox/query";
import { toastAction } from "../../redux/toastReducer";
import moment from "moment-timezone";
import { RadioComponent } from "../vehicle/VehicleAdd2/FormComponent";
import { PositiveBtn } from "../vehicle/VehicleAdd2/BottomNavigator";

const MY_INFO_ITEMS = [
    { label: "실명", id: "realname" },
    { label: "거주지주소", id: "address" },
    { label: "연락처", id: "phone" },
    { label: "이메일", id: "email" },
    { label: "계좌번호", id: "accountNumber" },
];

const MY_PROFILE_ITEMS = [
    { label: "닉네임", id: "nickname" },
    { label: "생년월일", id: "matching_profile.생년월일" },
    { label: "성별", id: "matching_profile.성별" },
    { label: "실내흡연여부", id: "matching_profile.실내흡연여부" },
    { label: "반려동물 탑승 방법", id: "matching_profile.반려동물 탑승 방법" },
    { label: "예상 운행거리", id: "matching_profile.예상 운행거리" },
    { label: "자기소개", id: "matching_profile.자기소개" },
];

const CONTRACT_INFO_ITEMS = [
    { label: "차량명", id: "model" },
    { label: "이용형태", id: "contractType" },
    { label: "월요금", id: "monthlyCharge" },
    { label: "km당요금", id: "kmCharge" },
];

export const MyDooricarPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userInfo = useSelector(({ user }) => user.user);
    const tokenInfo = useSelector(({ auth }) => auth.tokenInfo);
    const { data: _contracts, refetch } = useQuery(MY_CONTRACTS, {
        variables: { id: tokenInfo?.id },
    });
    const [userEditOpen, setUserEditOpen] = useState(false);
    const [profileEditOpen, setProfileEditOpen] = useState(false);
    const [contractTerminationOpen, setContractTerminationOpen] =
        useState(false);
    const [snackbar, setSnackbar] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const fileRef = useRef();
    const [updateUser] = useMutation(UPDATE_USER);

    const cardInfo = useMemo(() => {
        return get(userInfo, "credit_cards", []);
    }, [userInfo]);

    const walletHistory = useMemo(() => {
        return get(userInfo, "wallets", []);
    }, [userInfo]);

    const card = useMemo(() => {
        return cardInfo.length > 0 ? cardInfo[0] : null;
    }, [cardInfo]);

    const wallet = useMemo(() => {
        let sum = 0;
        for (let history of filterAvailableWallets(walletHistory)) {
            sum += history.amount;
        }
        return sum;
    }, [walletHistory]);

    const contracts = useMemo(() => {
        let data = get(
            _contracts,
            "usersPermissionsUser.data.attributes.contracts.data",
            null
        );
        if (data) {
            return data.map((c) => flatEntity(c));
        }
        return null;
    }, [_contracts]);

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, [tokenInfo]);

    const onClickAddCard = useCallback(async () => {
        if (!isEmpty(tokenInfo)) {
            const tossPayments = await loadTossPayments(TOSS_SUB_CLIENT_KEY);
            await tossPayments
                .requestBillingAuth("카드", {
                    customerKey: tokenInfo.id,
                    successUrl: window.location.origin + "/card_success",
                    failUrl: window.location.origin + "/card_fail",
                })
                .then((value) => {
                    console.log(value);
                })
                .catch((reason) => {
                    console.log(reason);
                });
        } else {
            console.log("로그인 정보가 없습니다");
        }
    }, [tokenInfo]);

    const onClickVisible = useCallback(
        async (contract) => {
            dispatch(loadingAction.loading(true));
            const res = await axios.post(
                SERVER_ADDRESS + "/api/vehicles/setVisible",
                {
                    contractId: contract.id,
                    visible: true,
                }
            );
            await refetch();
            dispatch(loadingAction.loading(false));
            dispatch(
                toastAction.toast({
                    severity: "success",
                    message: "차량이 활성화되었습니다.",
                })
            );
        },
        [dispatch, refetch]
    );

    const onClickHide = useCallback(
        async (contract) => {
            if (
                !window.confirm(
                    "차량을 비활성화하면 매칭 요청을 받을 수 없게 됩니다."
                )
            ) {
                return;
            }
            dispatch(loadingAction.loading(true));
            const res = await axios.post(
                SERVER_ADDRESS + "/api/vehicles/setVisible",
                {
                    contractId: contract.id,
                    visible: false,
                }
            );
            await refetch();
            dispatch(loadingAction.loading(false));
            dispatch(
                toastAction.toast({
                    severity: "success",
                    message: "차량이 비활성화되었습니다.",
                })
            );
        },
        [dispatch, refetch]
    );

    const onClickProfileHide = useCallback(async () => {
        if (
            !window.confirm(
                "프로필을 비활성화하면 호스트로부터 매칭 제안을 받을 수 없게 됩니다."
            )
        ) {
            return;
        }
        dispatch(loadingAction.loading(true));
        const payload = {
            refetchQueries: [
                { query: GET_ME, variables: { id: tokenInfo.id } },
            ],
            variables: {
                id: tokenInfo.id,
                data: {
                    mapMarker: false,
                },
            },
        };
        await updateUser(payload);
        dispatch(loadingAction.loading(false));
    }, [dispatch, tokenInfo]);

    const onClickProfileVisible = useCallback(async () => {
        dispatch(loadingAction.loading(true));
        const payload = {
            refetchQueries: [
                { query: GET_ME, variables: { id: tokenInfo.id } },
            ],
            variables: {
                id: tokenInfo.id,
                data: {
                    mapMarker: true,
                },
            },
        };
        await updateUser(payload);
        dispatch(loadingAction.loading(false));
    }, [dispatch, tokenInfo]);

    const getGuestContract = useCallback((contract) => {
        const now = moment.tz("Asia/Seoul").format("YYYY-MM-DD");
        const contracts = filter(
            contract.vehicle.contracts,
            (c) =>
                c.contractType === "DOORICAR" &&
                (!c.endDate || c.endDate >= now)
        );
        if (contracts.length > 0) {
            return contracts.sort((a, b) =>
                b.startDate.localeCompare(a.startDate)
            )[0];
        }
        return null;
    }, []);

    const onClickExit = useCallback(
        async (contract, endDate) => {
            const check = window.confirm("정말 해지하시겠습니까?");
            if (!check) {
                return;
            }
            const reason = window.prompt(
                "해지 사유를 입력해주세요 (상대방에게 전달되지 않습니다.)"
            );
            dispatch(loadingAction.loading(true));
            await axios.post(
                SERVER_ADDRESS + "/api/contracts/contractTermination",
                {
                    contractId: contract.id,
                    endDate: endDate,
                    reason: reason,
                }
            );
            dispatch(loadingAction.loading(false));
            setContractTerminationOpen(() => false);
            setSnackbar("해지 요청이 완료되었습니다.");
            setSnackbarOpen(() => true);
        },
        [dispatch]
    );

    const handleNotiToggle = useCallback(
        async (notificationType) => {
            dispatch(loadingAction.loading(true));
            const v = userInfo?.[notificationType];
            await axios.post(
                SERVER_ADDRESS + "/api/daily-updates/toggleNotification",
                {
                    notificationAllow: !v,
                    notificationType,
                }
            );
            const payload = {
                refetchQueries: [
                    { query: GET_ME, variables: { id: tokenInfo.id } },
                ],
                variables: {
                    id: tokenInfo.id,
                    data: {},
                },
            };
            await updateUser(payload);
            dispatch(loadingAction.loading(false));
        },
        [dispatch, tokenInfo, updateUser, userInfo]
    );

    const handleImage = useCallback(
        (e) => {
            if (e.target.files.length === 0) {
                return;
            }
            dispatch(loadingAction.loading(true));
            const data = new FormData();
            data.append("files", e.target.files[0]);
            data.append("ref", "plugin::users-permissions.user");
            data.append("refId", tokenInfo.id);
            data.append("field", "profile_image");

            axios
                .post(SERVER_ADDRESS + "/api/upload", data, {
                    onUploadProgress: (progressEvent) => {},
                })
                .then((res) => {
                    dispatch(authAction.refresh());
                    dispatch(loadingAction.loading(false));
                });
        },
        [dispatch, tokenInfo]
    );

    return (
        <_MyDooricarPage>
            <MyProfile onClick={() => fileRef.current.click()} />
            <div className="section">
                <TopButtons>
                    {/*<a target="_blank" href="https://docs.google.com/document/d/1jkshw1F3GRT3blmw8sqqR17cRoNOIq2A6x_cgM1VRgQ/edit?usp=sharing">[두리카 계약서]</a>*/}
                    <a
                        target="_blank"
                        href="https://copper-abrosaurus-467.notion.site/05eb68d07e134aa7adbb928499ce6650?pvs=74"
                    >
                        [두리카 공동사용규칙]
                    </a>
                </TopButtons>
            </div>
            <input
                style={{ display: "none" }}
                type="file"
                accept="image/jpeg, image/png"
                onChange={handleImage}
                ref={fileRef}
            />
            <div className="section">
                <div className="title">
                    내 프로필
                    <span
                        className="right_button"
                        onClick={() => navigate("/my/profile")}
                    >
                        수정하기
                    </span>
                </div>
                <div className="items">
                    {MY_PROFILE_ITEMS.map((item, idx) => {
                        return (
                            <MyPageInfoItem
                                {...item}
                                userInfo={userInfo}
                                key={idx}
                            />
                        );
                    })}
                    <_MyPageInfoItem>
                        <div className="left" />
                        <div className="title">프로필 비활성화</div>
                        <div className="value">
                            {userInfo?.mapMarker && (
                                <span
                                    className="right_button"
                                    onClick={() => onClickProfileHide()}
                                    style={{
                                        color: "white", // 빨간색 텍스트
                                        fontWeight: "500", // 약간 더 굵게
                                        backgroundColor: "#FF4040",
                                        border: "1px solid #FF4040", // 빨간색 테두리
                                    }}
                                >
                                    비활성화하기
                                </span>
                            )}
                            {!userInfo?.mapMarker && (
                                <span
                                    className="right_button"
                                    onClick={() => onClickProfileVisible()}
                                >
                                    활성화하기
                                </span>
                            )}
                        </div>
                    </_MyPageInfoItem>
                </div>
            </div>
            <Line />
            <div className="section">
                <div className="title">알림 설정</div>
                <div className="items">
                    <_MyPageInfoItem>
                        <div className="left" />
                        <div className="title">게스트 소식 알림</div>
                        <div className="value">
                            {userInfo?.guestNotificationAllow !== undefined && (
                                <span
                                    className="right_button"
                                    onClick={() =>
                                        handleNotiToggle(
                                            "guestNotificationAllow"
                                        )
                                    }
                                >
                                    {userInfo.guestNotificationAllow
                                        ? "끄기"
                                        : "켜기"}
                                </span>
                            )}
                        </div>
                    </_MyPageInfoItem>
                    <_MyPageInfoItem>
                        <div className="left" />
                        <div className="title">차량 소식 알림</div>
                        <div className="value">
                            {userInfo?.vehicleNotificationAllow !==
                                undefined && (
                                <span
                                    className="right_button"
                                    onClick={() =>
                                        handleNotiToggle(
                                            "vehicleNotificationAllow"
                                        )
                                    }
                                >
                                    {userInfo.vehicleNotificationAllow
                                        ? "끄기"
                                        : "켜기"}
                                </span>
                            )}
                        </div>
                    </_MyPageInfoItem>
                </div>
            </div>
            <Line />
            <div className="section">
                <div className="title">
                    회원 정보
                    <span
                        className="right_button"
                        onClick={() => setUserEditOpen(() => true)}
                    >
                        수정하기
                    </span>
                </div>
                <div className="items">
                    {MY_INFO_ITEMS.map((item, idx) => {
                        return (
                            <MyPageInfoItem
                                {...item}
                                userInfo={userInfo}
                                key={idx}
                            />
                        );
                    })}
                    <_MyPageInfoItem>
                        <div className="left" />
                        <div className="title">면허증사진</div>
                        <div className="value">
                            {userInfo?.licenseCard ? "등록됨" : "미등록"}
                        </div>
                    </_MyPageInfoItem>

                    {/*<_MyPageInfoItem>*/}
                    {/*    <div className="left" />*/}
                    {/*    <div className="title">결제카드</div>*/}
                    {/*    <div className="value">*/}
                    {/*        {card ? card.name + ' ' : ''}*/}
                    {/*        {card ?*/}
                    {/*            <span className={`right_button ${card && 'ml4'}`} onClick={onClickAddCard}>*/}
                    {/*                변경하기*/}
                    {/*            </span> :*/}
                    {/*            <span className={`right_button ${card && 'ml4'}`} onClick={onClickAddCard}>*/}
                    {/*                등록하기*/}
                    {/*            </span>}*/}
                    {/*    </div>*/}
                    {/*</_MyPageInfoItem>*/}

                    <_MyPageInfoItem>
                        <div className="left" />
                        <div className="title">두리카월렛</div>
                        <div className="value">
                            {commaNum(wallet)}P
                            <span
                                className={`right_button ${card && "ml4"}`}
                                onClick={() => {
                                    navigate("/my/wallet");
                                }}
                            >
                                상세내역 보기
                            </span>
                        </div>
                    </_MyPageInfoItem>
                    <_MyPageInfoItem>
                        <div className="left" />
                        <div className="title">서비스 탈퇴</div>
                        <div className="value">
                            <span
                                className={`right_button`}
                                onClick={() => {
                                    navigate("/my/leave");
                                }}
                            >
                                탈퇴 요청
                            </span>
                        </div>
                    </_MyPageInfoItem>
                </div>
            </div>

            {contracts &&
                contracts
                    ?.filter((c) => isAvailableContract(c))
                    .map((c, idx) => (
                        <>
                            <Line />
                            <div className="section">
                                <h3 className="title">
                                    차량 정보
                                    {c.contractType === "DOORICAR_WAITING" && (
                                        <span
                                            className="right_button"
                                            onClick={async () => {
                                                if (c.isGhost) {
                                                    dispatch(
                                                        loadingAction.loading(
                                                            true
                                                        )
                                                    );
                                                    const res =
                                                        await axios.post(
                                                            SERVER_ADDRESS +
                                                                "/api/vehicles/unsetGhost",
                                                            {
                                                                contractId:
                                                                    c.id,
                                                            }
                                                        );
                                                    await refetch();
                                                    dispatch(
                                                        loadingAction.loading(
                                                            false
                                                        )
                                                    );
                                                    dispatch(
                                                        toastAction.toast({
                                                            severity: "success",
                                                            message:
                                                                "차량 미응답 상태가 해제되었습니다.",
                                                        })
                                                    );
                                                }
                                                navigate(
                                                    "/vehicle_modify?id=" + c.id
                                                );
                                            }}
                                        >
                                            수정하기
                                        </span>
                                    )}
                                </h3>
                                <div className="items">
                                    {CONTRACT_INFO_ITEMS.map((item, idx) => {
                                        return (
                                            <ContractInfoItem
                                                {...item}
                                                contractInfo={c}
                                                key={idx}
                                            />
                                        );
                                    })}
                                    {c.contractType === "DOORICAR_WAITING" && (
                                        <_MyPageInfoItem>
                                            <div className="left" />
                                            <div className="title">
                                                차량 보러가기
                                            </div>
                                            <div className="value">
                                                <Link
                                                    className="right_button"
                                                    to={`/vehicle/${c.vehicle.id}?mode=doori`}
                                                >
                                                    이동하기
                                                </Link>
                                            </div>
                                        </_MyPageInfoItem>
                                    )}
                                    {c.contractType === "DOORICAR_WAITING" && (
                                        <_MyPageInfoItem>
                                            <div className="left" />
                                            <div className="title">
                                                차량 비활성화
                                            </div>
                                            <div className="value">
                                                {c.vehicle.visible && (
                                                    <span
                                                        className="right_button"
                                                        onClick={() =>
                                                            onClickHide(c)
                                                        }
                                                        style={{
                                                            color: "white", // 빨간색 텍스트
                                                            fontWeight: "500", // 약간 더 굵게
                                                            backgroundColor:
                                                                "#FF4040",
                                                            border: "1px solid #FF4040", // 빨간색 테두리
                                                        }}
                                                    >
                                                        비활성화하기
                                                    </span>
                                                )}
                                                {!c.vehicle.visible && (
                                                    <span
                                                        className="right_button"
                                                        onClick={() =>
                                                            onClickVisible(c)
                                                        }
                                                    >
                                                        활성화하기
                                                    </span>
                                                )}
                                            </div>
                                        </_MyPageInfoItem>
                                    )}
                                    {getGuestContract(c) && (
                                        <_MyPageInfoItem>
                                            <div className="left" />
                                            <div className="title">
                                                매칭기간
                                            </div>
                                            <div className="value">
                                                {getGuestContract(c).startDate}{" "}
                                                ~ {getGuestContract(c).endDate}
                                            </div>
                                        </_MyPageInfoItem>
                                    )}

                                    {getGuestContract(c) &&
                                        !getGuestContract(c).endDate && (
                                            <_MyPageInfoItem>
                                                <div className="left" />
                                                <div className="title">
                                                    매칭해지
                                                </div>
                                                <div className="value">
                                                    <span
                                                        className="right_button"
                                                        onClick={() =>
                                                            setContractTerminationOpen(
                                                                () =>
                                                                    getGuestContract(
                                                                        c
                                                                    )
                                                            )
                                                        }
                                                    >
                                                        해지요청
                                                    </span>
                                                </div>
                                            </_MyPageInfoItem>
                                        )}
                                    {getGuestContract(c) &&
                                        getGuestContract(c).endDate && (
                                            <_MyPageInfoItem>
                                                <div className="left" />
                                                <div className="title">
                                                    매칭해지
                                                </div>
                                                <div className="value">
                                                    <span
                                                        className="right_button"
                                                        onClick={() => {
                                                            alert(
                                                                "이미 종료 예정인 매칭입니다.\n기타 요청은 카카오톡으로 문의해주세요."
                                                            );
                                                        }}
                                                    >
                                                        해지요청
                                                    </span>
                                                </div>
                                            </_MyPageInfoItem>
                                        )}
                                </div>
                            </div>
                        </>
                    ))}
            <Line />
            <UserEditModal
                open={userEditOpen}
                onClose={() => setUserEditOpen(() => false)}
                onSuccess={() => {
                    setSnackbar("수정이 완료되었습니다.");
                    setSnackbarOpen(() => true);
                }}
            />
            <ProfileEditModal
                open={profileEditOpen}
                onClose={() => setProfileEditOpen(() => false)}
                onSuccess={() => {
                    setSnackbar("수정이 완료되었습니다.");
                    setSnackbarOpen(() => true);
                }}
            />
            <ContractTerminationModal
                open={contractTerminationOpen}
                onClose={() => setContractTerminationOpen(() => false)}
                onSuccess={(endDate) => {
                    onClickExit(contractTerminationOpen, endDate);
                }}
            />
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                autoHideDuration={4000}
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(() => false)}
            >
                <Alert severity="success">{snackbar}</Alert>
            </Snackbar>
        </_MyDooricarPage>
    );
};

export const ContractTerminationModal = ({ open, onClose, onSuccess }) => {
    const { form, onChange } = useForm({
        endDate: "",
    });

    const endDateOptions = useMemo(() => {
        const now = moment.tz("Asia/Seoul");
        const currentDay = now.date();
        const result = [];

        // 현재 날짜가 25일 이전인 경우
        if (currentDay < 25) {
            // 이번달 마지막날
            result.push({
                label: now.endOf("month").format("YYYY-MM-DD"),
                id: now.endOf("month").format("YYYY-MM-DD"),
            });
        }

        // 다음달 마지막날
        const nextMonth = now.add(1, "month");
        result.push({
            label: nextMonth.endOf("month").format("YYYY-MM-DD"),
            id: nextMonth.endOf("month").format("YYYY-MM-DD"),
        });

        return result;
    }, []);

    useEffect(() => {
        if (endDateOptions.length === 1) {
            onChange({ id: "endDate", value: endDateOptions[0].id });
        }
    }, [endDateOptions]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ style: { borderRadius: "12px" } }}
        >
            <div
                style={{
                    padding: "16px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    minWidth: "300px",
                }}
            >
                <h3>매칭 해지일자 선택</h3>
                <RadioComponent
                    id="endDate"
                    form={form}
                    onChange={onChange}
                    values={endDateOptions}
                />
                <PositiveBtn onClick={() => onSuccess(form.endDate)}>
                    해지하기
                </PositiveBtn>
            </div>
        </Dialog>
    );
};

const _MyDooricarPage = styled.div`
    .section {
        > .title {
            display: flex;
            padding-top: 24px;
            padding-left: 32px;
            padding-right: 32px;
            font-weight: 700;
            font-size: 14px;
            align-items: center;
            justify-content: space-between;
            > .right_button {
                cursor: pointer;
                font-size: 12px;
                color: #6993ff;
                text-decoration-line: underline;
                text-underline-offset: 2px;
                text-decoration-color: #6993ff;
                text-decoration-thickness: auto;
                text-decoration-style: solid;
            }
        }
    }

    .items {
        margin-top: 18px;
        margin-bottom: 18px;
    }
`;

const TopButtons = styled.div`
    display: flex;
    justify-content: center;
    font-size: 14px;
    padding: 10px;
    margin-top: 10px;
    > a {
        margin-left: 4px;
        margin-right: 4px;
    }
`;

const Line = styled.div`
    border-bottom: 1px solid #cccccc;
    margin-left: 24px;
    margin-right: 24px;
`;

const MyProfile = ({ onClick }) => {
    const userInfo = useSelector(({ user }) => user.user);
    return (
        <_MyProfile>
            <div className="image_wrap" onClick={onClick}>
                <img
                    src={
                        userInfo?.profile_image?.url ?? "/layout/char_doori.svg"
                    }
                ></img>
                <div className="edit">
                    <img src="/my/edit.svg" />
                </div>
            </div>
            <div className="name_box">
                <div>{userInfo?.nickname}</div>
            </div>
        </_MyProfile>
    );
};

const _MyProfile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    > .image_wrap {
        position: relative;
        cursor: pointer;
        padding: 14px;
        width: 120px;
        height: 120px;
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }

        > .edit {
            background-color: #33ccc1;
            border-radius: 50%;
            border: 2px solid white;
            position: absolute;
            right: 11px;
            bottom: 11px;
            width: 22px;
            height: 22px;
            padding: 6px;
            > img {
                width: 100%;
                height: 100%;
            }
        }
    }
    > .name_box {
        font-size: 24px;
        font-weight: 500;
    }
`;

const MyPageInfoItem = ({ userInfo, id, label }) => {
    const navigate = useNavigate();
    const tokenInfo = useSelector(({ auth }) => auth.tokenInfo);

    const getValue = useCallback(() => {
        return get(userInfo, `${id}`, "");
    }, [userInfo, id]);

    return (
        <_MyPageInfoItem>
            <div className="left" />
            <div className="title">{label}</div>
            <div className="value">{getValue()}</div>
        </_MyPageInfoItem>
    );
};

const ContractInfoItem = ({ contractInfo, id, label }) => {
    const value = useMemo(() => {
        if (id === "model") {
            return `${get(contractInfo, "vehicle.brand", "")} ${get(
                contractInfo,
                "vehicle.model",
                ""
            )}`;
        }
        if (id === "contractType") {
            return contractInfo?.contractType === "SOLOCAR"
                ? "쏠로카"
                : "두리카";
        }
        if (id === "monthlyCharge") {
            let charge = isMatchedContract(contractInfo)
                ? contractInfo?.monthlyChargeDuo
                : contractInfo?.monthlyCharge;
            if (!charge) {
                charge = contractInfo.doorivePriceMonth;
            }
            return commaNum(charge) + "원";
        }
        if (id === "kmCharge") {
            let charge = contractInfo?.kmCharge;
            if (!charge) {
                charge = contractInfo.doorivePriceKm;
            }
            return commaNum(charge) + "원";
        }
        return "";
    }, [id, contractInfo]);

    return (
        <_MyPageInfoItem>
            <div className="left" />
            <div className="title">{label}</div>
            <div className="value">{value}</div>
        </_MyPageInfoItem>
    );
};

const _MyPageInfoItem = styled.div`
    display: flex;
    margin-left: 32px;
    font-size: 12px;
    margin-top: 10px;
    align-items: center;

    > .left {
        margin-bottom: 1px;
        height: 12px;
        border-left: 2px solid #33ccc1;
    }

    > .title {
        padding-left: 6px;
        width: 110px;
        color: #555555;
        font-weight: 400;
        white-space: nowrap;
    }

    > .value {
        font-family: "Noto Sans KR";
        font-weight: 500;

        > .right_button {
            font-weight: 500;
            font-size: 11px;
            padding: 2px 4px;
            border-radius: 3px;
            border: 1px solid black;
            cursor: pointer;
        }

        > .ml4 {
            margin-left: 4px;
        }
    }
`;
