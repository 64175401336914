import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {cloneDeep, get, isEmpty} from "lodash";
import {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import {commaNum, useForm} from "../../toolbox";
import {useMutation, useQuery} from "@apollo/client";
import {UPDATE_USER} from "../../query/userQuery";
import {MB32} from "../../toolbox/UtilComponent";
import {loadingAction} from "../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {convertDooricar, getLoginLink, getUseTypeTxt} from "../../toolbox/format";
import {toastAction} from "../../redux/toastReducer";
import queryString from "query-string";
import {GET_DOORI_VEHICLE} from "../../query/vehicleQuery";
import LoadingIndicator from "../../layout/LoadingIndicator";
import {FormComponent, RadioComponent, RadioComponentSimple} from "./VehicleAdd2/FormComponent";
import {getDescriptionSimple, VehicleUseInfoSection3} from "./component/VehicleUseInfoSection";
import {flatEntity} from "../../toolbox/query";

const VehicleGuestWritePage = () => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const guestId = parsed?.guestId;
    const vehicleId = params.id;
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const user = useSelector(({user}) => user.user);

    const {data, refetch, loading} = useQuery(GET_DOORI_VEHICLE, {variables: {id: vehicleId}});
    const [vehicle, setVehicle] = useState(null);

    useEffect(() => {
        if (data) {
            const payload = flatEntity(get(data, "vehicle.data", null));
            if (payload) {
                let v = convertDooricar(payload);
                if (v) {
                    setVehicle(v);
                } else {
                    alert('이미 매칭이 되었거나 비활성화된 차량입니다.');
                    navigate(-1);
                }
            } else {
                alert('이미 매칭이 되었거나 비활성화된 차량입니다.');
                navigate(-1);
            }
        }
    }, [data]);

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, [tokenInfo]);

    const [finished, setFinished] = useState(false);
    const [updateUser] = useMutation(UPDATE_USER);

    const {form, onChange} = useForm({
        nickname: '',
        // phone: '',
        // address: '',
        // detail_address: '',
        '생년월일': '',
        '성별': '',
        // '운전경력': '',
        '실내흡연여부': '',
        '반려동물 탑승 방법': '',
        '자기소개': '',
        '시작 희망일': '',
        '예상 이용기간': '',
        '예상 운행거리': '',
        '추가운전자': '',
        '매칭유형': '',
        '호스트에게 한마디': '',
    });

    useEffect(() => {
        if (user && user.matching_profile) {
            for (let key in user.matching_profile) {
                if (form[key] !== undefined) {
                    onChange({id: key, value: user.matching_profile[key]});
                }
            }
        }
        if (user && user.nickname) {
            onChange({id: 'nickname', value: user.nickname});
        }
    }, [user]);

    const save = async () => {
        if (finished) {
            return;
        }

        const dto = cloneDeep(form);

        for (let key in dto) {
            if (key === '자기소개') {
                continue;
            }
            if (key === '호스트에게 한마디') {
                continue;
            }
            if (key === '추가운전자') {
                continue;
            }
            if (key === '매칭유형') {
                if (!vehicle.useTypes.map(u => u.id).includes(dto[key])) {
                    dispatch(toastAction.toast({severity: 'error', 'message': '매칭 유형을 체크해주세요'}));
                    return;
                }
            }
            if (!dto[key]) {
                dispatch(toastAction.toast({severity: 'error', 'message': '모든 내용을 채워주세요'}));
                return;
            }
        }

        const userDto = {
            nickname: dto.nickname,
            // phone: dto.phone,
            // address: dto.address + '\t' + dto.detail_address,
        }

        // const requestDto = {
        //     user: tokenInfo.id,
        //     contract: vehicle.contract,
        //     title: dto.title,
        //     availableDate: dto.availableDate,
        // }

        dispatch(loadingAction.loading(true));

        const payload = {
            id: tokenInfo.id,
            data: {
                matching_profile: form,
            }
        }

        if (user.nickname !== userDto.nickname) {
            payload.data.nickname = userDto.nickname;
        }

        window.fbq('track', 'AddToWishlist');
        updateUser({
            variables: payload,
        }).then(() => {});

        if (guestId) {
            const res = await axios.post(SERVER_ADDRESS + '/api/vehicles/matchingRequest', {
                title: '찜한 렌터카 차량에 요청',
                payload: {
                    신청한사람: `${userDto.nickname} (${tokenInfo?.id}, ${user.phone})`,
                    신청받은사람: `${vehicle?.user?.nickname} (${vehicle?.user?.id}, ${vehicle?.user?.phone}, https://www.dooricar.com/user/${guestId})`,
                    차량: `${vehicle.numberPlate} (${vehicle.id})`,
                    차량페이지: `https://www.dooricar.com/vehicle/${vehicleId}?mode=solo&guestId=${guestId}`,
                    ...dto,
                },
            });
        } else {
            const res = await axios.post(SERVER_ADDRESS + '/api/vehicles/matchingRequest', {
                title: '호스트 차량에 요청',
                contractId: vehicle.contract,
                payload: {
                    게스트: `${userDto.nickname} (${tokenInfo?.id}, ${user.phone})`,
                    호스트: `${vehicle?.user?.nickname} (${vehicle?.user?.id}, ${vehicle?.user?.phone})`,
                    차량: `${vehicle.numberPlate} (${vehicle.id})`,
                    차량페이지: `https://www.dooricar.com/vehicle/${vehicleId}?mode=doori`,
                    ...dto,
                },
            });

            // const payload = JSON.stringify({
            //     게스트: `${userDto.realname} (${tokenInfo?.id}, ${user.phone})`,
            //     차량: `${vehicle.numberPlate} (${vehicle.id})`,
            //     신청서: dto,
            // }, null, 2);
            // await asyncSendTelegram("타던차 매칭 신청 (호스트 차량)\n" + payload);
            // const res = await axios.post(SERVER_ADDRESS + '/notifications/matchingSend', {contractId: vehicle.contract}, {});
        }
        setFinished(true);
        dispatch(loadingAction.loading(false));
        alert("상대방이 매칭을 수락하면 담당 매니저가 개별적으로 연락을 드려서 세부절차를 안내드립니다.")
    }

    const titlePlaceholder = "";
    return <PageWrap>
        <Card1>
            <h3>
                매칭 요청하기
            </h3>
        </Card1>
        <div style={{padding: '12px'}}>
            {vehicle && <TextBox>
                {`차종 : ${vehicle.brand} ${vehicle.model}`}<br/><br/>
                {vehicle.useTypes ? <>
                    {`월이용료`}<br/>
                    {vehicle.useTypes.map(u =>
                        `${getUseTypeTxt(u.id, vehicle.useTypeDetail)} : ${commaNum(u.price)}원`
                    ).reduce((acc, x, idx) => acc === null ? [x] : [acc,
                        <br/>,
                        x], null)}
                    <br/>
                    <br/>
                </>:<>
                    {`월이용료 : ${commaNum(vehicle.priceDuo)}원`}<br/>
                </>}
                {/*{`월세금분담액 : ${commaNum(calcDooriTax(vehicle))}원`}<br/>*/}
                {`거리당주유비 : ${commaNum(vehicle.kmCharge)}원 / km`}<br/>
                {/*{vehicle.priceOil ? <>*/}
                {/*    /!*{`거리당관리비 : ${commaNum(vehicle.priceManage)}원 / km`}<br/>*!/*/}
                {/*    {`거리당주유비 : ${commaNum(vehicle.kmCharge)}원 / km`}<br/>*/}
                {/*</>:<>*/}
                {/*    {`거리당비용 : ${commaNum(vehicle.kmCharge)}원 / km`}<br/>*/}
                {/*</>}*/}
                {`보증금 : 월이용료의 3배`}
            </TextBox>}
        </div>
        <div style={{padding: '6px 12px', gap: '28px', display: 'flex', flexDirection: 'column'}}>
            <FormComponent type="text" id="nickname" title="닉네임" onChange={onChange} form={form} />
            {/*<FormComponent type="tel" id="phone" title="연락처" onChange={onChange} form={form} />*/}
            {/*<FormComponent type="text" id="address" title="주소" onChange={onChange} form={form} />*/}
            {/*<FormComponent type="text" id="detail_address" title="상세 주소" onChange={onChange} form={form} />*/}
            <FormComponent type="tel" id="생년월일" placeholder="ex) 940214" title="생년월일 (주민번호 앞자리)" onChange={onChange} form={form} />
            <RadioComponentSimple id="성별" title="성별" form={form} onChange={onChange} values={[
                {id: '남', label: '남'},
                {id: '여', label: '여'},
            ]}/>
            {/*<FormComponent type="text" placeholder="ex) 5년 무사고, 10년 등 자유롭게 작성해주세요" id="운전경력" title="운전 경력" onChange={onChange} form={form} />*/}
            <RadioComponentSimple id="실내흡연여부" title="실내흡연여부" form={form} onChange={onChange} values={[
                  {id: '차량 내 비흡연', label: '차량 내 비흡연'},
                  {id: '차량 내 흡연 필요', label: '차량 내 흡연 필요'},
            ]}/>
            <RadioComponentSimple id="반려동물 탑승 방법" title="반려동물 탑승 방법" form={form} onChange={onChange} values={[
                {id: '없음', label: '없음'},
                {id: '케이지 탑승', label: '케이지 탑승'},
                {id: '그냥 탑승', label: '그냥 탑승'},
            ]}/>
            <div>
                <div style={{
                    color: "#212121",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    marginBottom: "4px",}}>매칭 유형</div>
                <VehicleUseInfoSection3 vehicle={vehicle} id="매칭유형" form={form} onChange={onChange} />
            </div>
            {/*<RadioComponent id="매칭유형" title="매칭 유형" descriptionCollapse={true} form={form} onChange={onChange} values={*/}
            {/*    vehicle?.useTypes?.map((u) => ({*/}
            {/*        id: u.id,*/}
            {/*        label: getUseTypeTxt(u.id),*/}
            {/*        description: getDescriptionSimple(u.id),*/}
            {/*        additional: u.id === 'DAY_SELECT' && `호스트 요일지정 : ${vehicle.daySelect ? vehicle.daySelect.replace('형', '전부'): '없음'}`*/}
            {/*    }))?? []}/>*/}
            <FormComponent type="date" id="시작 희망일" title="희망하는 이용시작일을 입력해주세요." onChange={onChange} form={form} />

            <FormComponent type="text" id="예상 이용기간" placeholder="ex) 5개월, 1년" title="예상하는 이용 기간을 입력해주세요." onChange={onChange} form={form} />
            <FormComponent type="text" id="예상 운행거리" placeholder="ex) 500" title="예상하는 월간 운행거리(km)를 입력해주세요." description="월 2,000km 초과 주행 시 km당 100원 내외의 초과주행료가 발생합니다." onChange={onChange} form={form} />
            <FormComponent type="text" id="추가운전자" description="운전자의 인적사항과 면허증을 제출하여 호스트의 승낙을 얻어야합니다." placeholder="ex) 배우자, 30세" title="추가운전자가 있다면 관계와 연령(만)을 입력해주세요. (선택사항)" onChange={onChange} form={form} />
            <FormComponent type="textarea" id="호스트에게 한마디" title="호스트에게 하고싶은 한마디 (선택사항)" placeholder="운전경력/습관 등 나를 어필할 수 있는 내용이나 호스트에게 궁금한 점 등을 자유롭게 작성해주세요." onChange={onChange} form={form} />
        </div>
        {/*<FormComponent type="date" id="availableDate" title="시작 희망일" onChange={onChange} form={form} />*/}
        {/*<TestComponent type="text" id="title" title="호스트에게 한마디" placeholder={titlePlaceholder} onChange={onChange} form={form} />*/}
        <div style={{padding: '12px 12px 0'}}>
            <div className="title">기타 문의사항은 우측하단 톡상담을 이용해주세요</div>
        </div>
        <MB32/>
        <SubmitBtn finished={finished} onClick={save}>{finished? '신청완료': '신청하기'}</SubmitBtn>
        <LoadingIndicator isVisible={loading || !user} />
    </PageWrap>
}

export default VehicleGuestWritePage;

const PageWrap = styled.div`
  box-shadow: 0px -6px 20px -9px rgba(0, 0, 0, 0.18);
`;

const SubmitBtn = styled.div`
  box-sizing: border-box;
  margin-left: 40px;
  margin-right: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;

  ${(props) => props.finished && css`
    background-color: gray;
    cursor: not-allowed;
  `}
`
const Card1 = styled.div`
  padding: 30px 20px 20px;
  > h3 {
    font-family: 'Pretendard', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #00D0C1;
  }

  > div {
    > h1 {
      font-family: 'Pretendard', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
      color: #00D0C1;
      text-align: center;
    }
    > h2 {
      font-family: 'Pretendard', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
      color: black;
      text-align: center;
      margin-bottom: 42px;
    }

    > p {
      font-family: 'Pretendard', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #646464;
      text-align: center;
    }
  }
`

const TextBox = styled.div`
  padding: 16px 12px;
  border-radius: 8px;
  background: var(--gray-scale-gray-200, #F5F5F5);

  color: var(--gray-scale-gray-700, #939393);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */

  ${(props) => props.onClick && css`
    cursor: pointer;
  `}
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
  }
  .thin {
    font-weight: 400;
  }
  .link {
    font-weight: 600;
    text-decoration-line: underline;
    cursor: pointer;
  }
`
