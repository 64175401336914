import styled, {css} from "styled-components";
import {filter, find, get} from "lodash";
import {GET_USER, MY_CONTRACTS, UPDATE_USER} from "../../query/userQuery";
import {useMutation, useQuery} from "@apollo/client";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {Dialog, Popover} from "@mui/material";
import {asyncSendTelegram, openKakaoChat, useForm} from "../../toolbox";
import {useDispatch, useSelector} from "react-redux";
import {loadingAction} from "../../redux/loadingReducer";
import {authAction} from "../../redux/authReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import HelpIcon from "@mui/icons-material/Help";
import {VehicleThumbnail} from "../vehicle/component/VehicleThumbnail";
import VehicleLocationSection from "../vehicle/component/VehicleLocationSection";
import {MB8} from "../../toolbox/UtilComponent";
import {flatEntity} from "../../toolbox/query";
import moment from "moment-timezone";
import {calcAge, calcTimeDiff, convertDooricar} from "../../toolbox/format";
import {GUEST_WANTED} from "../../toolbox/guestTag";
import { isAvailableContract } from "../../toolbox/calculate";
import { useMemo } from "react";
import { useCallback } from "react";
import { TinyVehicle } from "../vehicle/component/ListVehicle2";

const MyThumbnail = styled(VehicleThumbnail)`
  width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  margin-bottom: 2px;
`

const VERIFIES = [
    {id: '매너인증', desc: '당근 매너온도가 40도 이상인 경우 심사에 통과합니다.'},
    {id: '안전운전인증', desc: '티맵 안전운전 점수가 70점 이상인 경우 심사에 통과합니다.'},
    {id: '신용점수인증', desc: '신용점수가 상위 80% 이상인 경우 심사에 통과합니다.'},
    {id: '담보인증', desc: '부동산 등기권리증, 금융자산 보유잔고, 재산세 납부내역서 등 담보자산을 증빙한 경우 심사에 통과합니다.'},
    {id: '신분인증', desc: '재직증명서, 졸업증명서, 재학증명서 등을 통해 신분을 증빙할 경우 심사에 통과합니다. '},
    {id: '무사고인증', desc: '보험접수이력을 통해 무사고를 증빙할 경우 심사에 통과합니다.'},
    {id: '평화인증', desc: '두리카 이용기간 동안 분쟁/사고 등 트러블이 없을 경우 심사에 통과합니다.'},
]

const _VerifyTag = styled.div`
  span {
    font-weight: 700;
    border-radius: 8px;
    background-color: #EEEEEE;
    padding: 4px 6px;
    line-height: 30px;
    color: #a6a6a6;
    cursor: default;

    ${(props) => props.grade == 1 && css`
      background-color: #ffe546;
      color: #222222;
    `}
    ${(props) => props.grade == 2 && css`
      background-color: #EEEEEE;
      color: #222222;
    `}
    ${(props) => props.grade == 3 && css`
      background-color: #ff9540;
      color: #222222;
    `}
  }
`

const VerifyTag = ({id, desc, grade}) => {
    const guideIcon = useRef();
    const [guide, setGuide] = useState(false);

    const gradeTxt = () => {
        if (grade == 1) {
            return  '🥇';
        } else if (grade == 2) {
            return  '🥈';
        } else if (grade == 3) {
            return  '🥉';
        }
        return '';
    }
    return <_VerifyTag grade={grade} ref={guideIcon} onMouseEnter={() => setGuide(true)} onMouseLeave={() => setGuide(false)} >
        <span className={grade}>{id} {gradeTxt()}</span>
        <Popover open={guide} anchorEl={guideIcon.current} style={{pointerEvents: "none"}} anchorOrigin={{vertical: 'bottom'}}>
            <div style={{width: "200px", overflow: "hidden", padding: "8px", lineHeight: "22px"}}>
                {desc}
            </div>
        </Popover>
    </_VerifyTag>
}

export const UserPage = () => {
    const params = useParams();
    const userId = params.id;
    const location = useLocation();
    const navigate = useNavigate();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);

    const {data, refetch} = useQuery(GET_USER, {variables: {id: userId}, fetchPolicy: 'cache-first'});
    const {data: myContracts} = useQuery(MY_CONTRACTS, {
        variables: {
            id: tokenInfo?.id,
        },
        skip: !tokenInfo,
        fetchPolicy: "cache-first",
    });

    const [myVehicles, setMyVehicles] = useState([]);
    const [requestOpen, setRequestOpen] = useState(false);

    const user = useMemo(() => {
        if (data) {
            let user = get(data, "usersPermissionsUser.data", null);
            user = flatEntity(user);
            return user;
        }
        return null;
    }, [data]);

    const getTags = useCallback(() => {
        if (user?.matching_profile?.["원하는 차량"]) {
            const result = [];
            for (let category of GUEST_WANTED) {
                // result.push({})
            }
            return user.matching_profile["원하는 차량"].split(',').map((tag) => '#' + tag).join(', ');
        }
        return ''
    }, [user]);

    const getVerifies = useCallback(() => {
        const verifies = user?.verifies ?? [];
        const result = [];
        for (let v of VERIFIES) {
            const userVerify = find(verifies, (_v) => _v.id === v.id);
            const d = {
                id: v.id,
                desc: userVerify?.desc ?? v.desc,
                grade: userVerify?.grade ?? '4',
            }
            result.push(d);
        }
        return result;
    }, [user]);

    const profileKeys = ["성별", "생년월일", "실내흡연여부", "예상 운행거리", "자기소개", "반려동물 탑승 방법"];
    const profile = useMemo(() => {
        const matchingProfile = user?.matching_profile ?? null;
        if (matchingProfile) {
            return Object.keys(matchingProfile).reduce((result, key) => {
                if (profileKeys.includes(key)) {
                    result[key] = matchingProfile[key];
                }
                return result;
            }, {});
        }
        return null;
    }, [user, profileKeys]);

    const compareByCustomOrder = useCallback((keyA, keyB) => {
        const sortOrder = ["성별", "생년월일", "실내흡연여부", "예상 운행거리", "반려동물 탑승 방법", "자기소개"];
        const indexA = sortOrder.indexOf(keyA);
        const indexB = sortOrder.indexOf(keyB);
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
    }, []);

    const filterContract = useCallback((user) => {
        let contracts = user.contracts;
        return filter(contracts, c => c.vehicle && !c.vehicle.draftVehicle && c.vehicle.doorive).sort((ca, cb) => {
            if (ca.endDate === cb.endDate) {
                return 0;
            }
            if (!ca.endDate) {
                return -1;
            }
            if (!cb.endDate) {
                return 1;
            }
            if (ca.endDate < cb.endDate) {
                return 1;
            }
            return -1;
        });
    }, []);

    useEffect(() => {
        if (myContracts) {
            let contracts = get(myContracts, "usersPermissionsUser.data.attributes.contracts.data", null);
            contracts = contracts.map(c => flatEntity(c));
            contracts = contracts.filter((c) => isAvailableContract(c) && c.vehicle.doorive && c.contractType === 'DOORICAR_WAITING');
            let vehicles = contracts.map(c => convertDooricar(c.vehicle, false, true));
            setMyVehicles(vehicles);
        }
    }, [myContracts]);

    const contractToString = useCallback((contract) => {
        let startAt = contract.startDate;
        if (!startAt) {
            startAt = moment.tz(contract.createdAt, 'Asia/Seoul').format('YYYY-MM-DD');
        }
        return `${startAt? moment.tz(startAt, 'Asia/Seoul').format('YY.MM.DD') :''} ~ ${contract.endDate? moment.tz(contract.endDate, 'Asia/Seoul').format('YY.MM.DD') : '종료예정없음'}: ${contract.vehicle.brand} ${contract.vehicle.model} (${contract.contractType === 'DOORICAR'? '게스트': '호스트'})`;
    }, []);

    if (!user) {
        return <_MyDooricarPage>
        </_MyDooricarPage>
    }
    return <_MyDooricarPage>
        <>
            <MyProfile userInfo={user} />
            <div className="section">
                {user?.matching_profile?.["원하는 차량"] && <>
                    <h4>원하는 차량</h4>
                    {GUEST_WANTED.map(category => <ProfileItem profileKey={category.title} profileValue={category.tags.filter(t => user?.matching_profile?.["원하는 차량"].includes(t.id)).map(t => t.tag).join(', ') } />)}
                </>}
            </div>
            <Line/>
            <div className="section">
                <h4>매칭 프로필</h4>
                {profile && Object.entries(profile)
                    .sort(([keyA], [keyB]) => compareByCustomOrder(keyA, keyB))
                    .map(([key, value]) => (<ProfileItem profileKey={key} profileValue={value} />
                    ))}
                {filterContract(user).length > 0 && <ProfileItem profileKey="이용이력" profileValue={filterContract(user).map(c => contractToString(c)).join('\n')} />}
                <ProfileItem profileKey="매칭희망지역" profileValue={`${user?.address?.split('\t')[0]} 인근`} />
                <div className="location">
                    <MyVehicleLocationSection vehicle={{id: user?.id, type: 'user', longitude: user?.lng, latitude: user?.lat}} />
                </div>
            </div>
            <Line/>
            <Actions>
                {String(userId) === String(tokenInfo?.id) && <RequestBtn content="프로필 수정하기" onClick={() => {
                    navigate('/my/profile');
                }} />}
                {String(userId) !== String(tokenInfo?.id) && <>
                    <RequestBtn content="매칭 제안하기" onClick={() => {
                        setRequestOpen(true);
                    }} />
                    <RequestBtn content="채팅하기" onClick={() => {
                        navigate('/my/chat_start?userId=' + user?.id);
                    }} />
                </>}
            </Actions>
        </>
        <MyVehicleSelectDialog open={requestOpen} onClose={async (selected) => {
            setRequestOpen(false);
            if (selected) {
                console.log(selected);
                if (!selected.visible) {
                    if (window.confirm('비활성화된 차량입니다. 비활성화를 해제하고 매칭 제안을 진행하시겠습니까?')) {
                        const res = await axios.post(SERVER_ADDRESS + '/api/vehicles/setVisible', {
                            contractId: selected.hostContract.id,
                            visible: true,
                        });
                    }
                }
                const message = window.prompt('매칭 제안 메시지를 입력해주세요.', `안녕하세요, ${selected.brand} ${selected.model} 차주 ${selected.hostUser.nickname}입니다. 제 차량 확인 후 맘에 드신다면 매칭요청 부탁드릴게요! 궁금하신 점은 편하게 문의해주세요.`);
                if (message !== null) {
                    axios.post(`${SERVER_ADDRESS}/api/chat-rooms/sendMatchingMessage`, {
                        targetUserId: user?.id,
                        vehicleId: selected.id,
                        contractId: selected.hostContract.id,
                        message: message,
                    }).then(res => {
                        navigate(`/my/chat/${res.data}`);
                    });
                }
            }
        }} myVehicles={myVehicles} />
    </_MyDooricarPage>;
}

const _MyVehicleSelectDialog = ({className, open, onClose, myVehicles}) => {
    const [selected, setSelected] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (myVehicles.length !== 0) {
            setSelected(myVehicles[0]);
        }
    }, [myVehicles]);

    return <Dialog open={open} onClose={() => onClose(null)}>
        <div className={className}>
            <h3>차량 선택</h3>
            <div className="vehicles">
                {myVehicles && myVehicles.length === 0 && <div style={{padding: '10px 22px', textAlign: 'center'}}>
                    차량이 없습니다. 차량을 등록해주세요.
                    <div className="btn" onClick={() => navigate('/vehicle/write')}>차량 등록하러가기</div>
                </div>}
                {myVehicles.map(v => (
                    <div key={v.id} style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                        <input 
                            type="radio" 
                            id={`vehicle-${v.id}`} 
                            name="vehicle" 
                            value={v.id} 
                            checked={selected && selected.id === v.id}
                            onChange={() => setSelected(v)}
                            style={{ marginRight: '10px' }}
                        />
                        <label htmlFor={`vehicle-${v.id}`} style={{ flexGrow: 1 }}>
                            <TinyVehicle 
                                vehicle={v} 
                                imageHide={false} 
                                hideManner={true}
                            />
                        </label>
                    </div>
                ))}
            </div>
            {myVehicles && myVehicles.length > 0 && <div className="btn-box">
                <div className="btn" onClick={() => onClose(null)}>취소</div>
                <div className="btn" onClick={() => onClose(selected)}>확인</div>
            </div>}
        </div>
    </Dialog>
}

export const MyVehicleSelectDialog = styled(_MyVehicleSelectDialog)`
    padding: 12px;
    .vehicles {
      .btn {
        display: flex;
        justify-content: center;
        margin: 10px 0;
        background-color: #33CCC1;
        color: white;
        padding: 12px;
        border-radius: 8px;
        cursor: pointer;
      }
    }
    .btn-box {
        display: flex;
        justify-content: space-between;
        padding: 12px 24px;
        gap: 12px;
        .btn {
            flex-grow: 1;
            text-align: center;
            background-color: #33CCC1;
            color: white;
            padding: 12px;
            border-radius: 8px;
            cursor: pointer;
        }
    }
`

const MyVehicleLocationSection = styled(VehicleLocationSection)`
    padding: 0;
`

const _MyDooricarPage = styled.div`
  .section {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 24px 32px;
    > .title {
      display: flex;
      //padding-left: 32px;
      //padding-right: 32px;
      line-height: 18px;
      flex-wrap: wrap;
      font-weight: 700;
      font-size: 14px;
      align-items: center;
      gap: 4px;
      > .right_button {
        cursor: pointer;
        font-size: 12px;
        color: #6993FF;
        text-decoration-line: underline;
        text-underline-offset: 2px;
        text-decoration-color: #6993FF;
        text-decoration-thickness: auto;
        text-decoration-style: solid;
      }
    }

    > .action-btn {
      cursor: pointer;
      flex-grow: 1;
      box-sizing: border-box;
      background-color: #33CCC1;
      border-radius: 4px;
      margin-top: 6px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 18px;
      width: 180px;
      padding: 6px;
      color: white;
      text-align: center;
    }
  }

  .section2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 0 24px;
    > .pad32 {
      padding-left: 32px;
      padding-right: 32px;
    }
    > .title {
      display: flex;
      padding-left: 32px;
      padding-right: 32px;
      line-height: 18px;
      flex-wrap: wrap;
      font-weight: 700;
      font-size: 14px;
      align-items: center;
      gap: 4px;
    }
  }

  .items {
    margin-top: 18px;
    margin-bottom: 18px;
  }
  
  .location {
    margin: 0 0 18px;
  }
  
  .vehicles {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 24px;
    
    > .vehicle {
      box-sizing: border-box;
      max-width: calc(50% - 8px);
      flex-basis: 140px;
      flex-grow: 1;
      height: 120px;
      margin: 4px 4px 36px 4px;
      text-align: center;
      cursor: pointer;
      position: relative;
    }
  }
`

const Line = styled.div`
  border-bottom: 1px solid #CCCCCC;
  margin-left: 24px;
  margin-right: 24px;
`

const MyProfile = ({userInfo}) => {
    return <_MyProfile>
        <div className="image_wrap">
            <img src={userInfo?.profile_image?.url ?? '/layout/char_doori.svg'}></img>
            {/*<div className="edit">*/}
            {/*    <img src="/my/edit.svg" />*/}
            {/*</div>*/}
        </div>
        <div className="name_box">
            <div>{userInfo?.nickname}</div>
            {userInfo?.lastLoginAt && <div className="last_login">최근 접속 : {calcTimeDiff(userInfo?.lastLoginAt)}</div>}
        </div>
    </_MyProfile>
}

const _MyProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  > .image_wrap {
    position: relative;
    padding: 14px;
    width: 120px;
    height: 120px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    
    > .edit {
      background-color: #33CCC1;
      border-radius: 50%;
      border: 2px solid white;
      position: absolute;
      right: 11px;
      bottom: 11px;
      width: 22px;
      height: 22px;
      padding: 6px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
  > .name_box {
    font-size: 24px;
    font-weight: 500;
    text-align: center;

    > .last_login {
      margin-top: 4px;
      font-size: 12px;
      color: #777777;
    }
  }
`

const _RequestBtn = ({className, onClick, content}) => {
    return <div className={className} onClick={onClick}>
        <div>
            {content}
        </div>
    </div>
}

const Actions = styled.div`
    display: flex;
`
const RequestBtn = styled(_RequestBtn)`
  padding: 30px 12px;
  z-index: 0;
  position: sticky;
  bottom: 0px;
  width: 100%;
  max-width: 450px;
  background: #ffffff;
  box-sizing: border-box;
    div {
      padding: 14px 0;
      text-align: center;
      color: #ffffff;
      background: #33ccc1;
      font-size: 16px;
      font-weight: bold;
      border-radius: 8px;
      &:hover {
        cursor: pointer;
      }
    }
`

const ProfileItem = ({profileKey, profileValue}) => {
    let title = profileKey;
    let value = profileValue;

    // if (title === '호스트에게 한마디') {
    //     title = '소개글';
    // }

    if (title === '생년월일') {
        title = "나이";
        value = `${calcAge(moment.tz(profileValue, 'YYYYMMDD', 'Asia/Seoul'))}세`

    }

    if (title === '예상 운행거리') {
        title = '평균 월주행거리'
    }

    const isMultiLine = () => {
        const multilines = ["자기소개", "이용이력"];
        return multilines.includes(profileKey);
    }

    return (
        <_ProfileItem>
            <strong>{title}{!isMultiLine() && ':'}</strong> {isMultiLine() && <br/>}{value}
        </_ProfileItem>
    )
}

const _ProfileItem = styled.div`
  //display: flex;
  gap: 4px;
  background: #f3f3f3;
  padding: 10px;
  //margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  white-space: pre-wrap;
  word-break: break-all;
  align-items: flex-start;
  line-height: 1.6;
`

export const PhoneDialog = ({open, onClose, vehicle}) => {
    const { form, onChange, resetValue } = useForm();

    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);
    const dispatch = useDispatch();

    const [updateUser] = useMutation(UPDATE_USER);

    useEffect(() => {
        if (userInfo?.phone) {
            onChange({id: 'phone', value: userInfo.phone});
        }
    }, [userInfo]);

    const handleClick = async () => {
        if (!form.phone) {
            alert("연락처를 입력해주세요.")
            return;
        }

        dispatch(loadingAction.loading(true));

        if (userInfo.phone !== form.phone) {
            const payload = {
                variables: {
                    id: tokenInfo.id,
                    data: {
                        phone: form.phone,
                    }
                }
            };
            updateUser(payload).then(res => {
                dispatch(authAction.refresh());
            });
        }

        const payload = JSON.stringify({
            realname: userInfo.realname,
            phone: form.phone,
            vehicle: `${vehicle.model} (${vehicle.numberPlate})`,
        }, null, 2);
        await asyncSendTelegram("렌터카 신청\n" + payload);
        alert("상담을 요청하였습니다. 연락이 올때까지 조금만 기다려주세요.");
        onClose();
        dispatch(loadingAction.loading(false));
    }

    return <Dialog open={open}>
        <_DMRequestDialog>
            <div className="content">
                <h2 className="title">렌터카 신청하기</h2>
                <p className="name">연락처</p>
                <div className="input-box">
                    <input className="input" placeholder="010-1234-5678" type="text" value={form.phone} onChange={(e => onChange({id: 'phone', value: e.target.value}))} />
                </div>
                <p className="name">두리카 매니저가 위 번호로 연락드려 출고 절차를 진행해드립니다.</p>
                <MB8 />
                <div className="btn-box">
                    <div className="btn" onClick={onClose}>
                        취소
                    </div>
                    <div className="btn" onClick={handleClick}>
                        확인
                    </div>
                </div>
            </div>
        </_DMRequestDialog>
    </Dialog>
}
export const DMRequestDialog = ({open, selected, selectedVehicle, onClose, isHost, user}) => {
    const { form, onChange, resetValue } = useForm();
    const [guide, setGuide] = useState(false);

    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);
    const dispatch = useDispatch();

    const [updateUser] = useMutation(UPDATE_USER);

    useEffect(() => {
        if (userInfo?.contact) {
            onChange({id: 'contact', value: userInfo.contact});
        }
    }, [userInfo]);

    useEffect(() => {
        window.fbq('track', 'ViewContent');
    }, [])

    const handleClick = async () => {
        if (!form.contact) {
            alert("연락받으실 연락처를 입력해주세요.")
            return;
        }

        let message = form.message;
        if (!message) {
            message = '안녕하세요 관심있는 차량이 있습니다';
        }

        dispatch(loadingAction.loading(true));

        if (userInfo.contact !== form.contact) {
            const payload = {
                variables: {
                    id: tokenInfo.id,
                    data: {
                        contact: form.contact,
                    }
                }
            };
            updateUser(payload).then(res => {
                dispatch(authAction.refresh());
            });
        }

        const payload = {
            receiverId: selected.id,
        }

        if (selectedVehicle) {
            payload.vehicleId = selectedVehicle.id;
        }

        const res = await axios.post(SERVER_ADDRESS + '/notifications/dmSend', payload, {});
        alert("대화를 요청하였습니다. 연락이 올때까지 조금만 기다려주세요.");
        onClose();
        dispatch(loadingAction.loading(false));
    }

    const guideIcon = useRef();

    return <Dialog open={open}>
        <_DMRequestDialog>
            <div className="content">
                <h2 className="title">{isHost? '호스트': '게스트'} {user?.nickname}님께 {selectedVehicle? `${selectedVehicle?.model} `: ''}대화 요청</h2>
                <p className="name">연락받을 연락처{" "}
                    <HelpIcon ref={guideIcon} fontSize="16px" onMouseEnter={() => setGuide(true)} onMouseLeave={() => setGuide(false)} />
                    <Popover open={guide} anchorEl={guideIcon.current} style={{pointerEvents: "none"}}>
                        <div style={{width: "200px", overflow: "hidden", padding: "8px", lineHeight: "22px"}}>
                            오픈채팅 링크 가이드<br/><br/>
                            1. 카카오톡 실행<br/>
                            2. 채팅 우상단 ‘새로운 채팅’ 클릭(+모양)<br/>
                            3. 오픈채팅 선택<br/>
                            4. 1:1채팅방 선택<br/>
                            5. 채팅방이름입력 후 우상단 ‘완료’ 선택<br/>
                            6. 링크공유 선택해서 URL 복사 후 이곳에 붙여넣기
                        </div>
                    </Popover>
                </p>
                <div className="input-box">
                    <input className="input" placeholder="오픈카톡 URL 권장" type="text" value={form.contact} onChange={(e => onChange({id: 'contact', value: e.target.value}))} />
                </div>

                <p className="desc">
                    {isHost && <>
                        ※ 호스트는 게스트와 같이 사용할 주차장을 제공하고 차량 정비 등 전반적인 관리를 책임집니다.<br/><br/>
                        차를 주로 언제 이용하는지, 주차장은 어떻게 사용하는지 등에 관하여 자유롭게 이야기해보세요.<br/><br/>
                        대화 후 매칭 희망시 ‘매칭요청’ 버튼을 클릭해주세요.<br/><br/>
                        운영정책에 관한 질문은 두리카 팀에 문의해주세요.<br/><br/>
                    </>}
                    {!isHost && <>
                        ※ 호스트는 게스트와 같이 사용할 주차장을 제공하고 차량 정비 등 전반적인 관리를 책임집니다.<br/><br/>
                        누가 호스트가 될 지, 이용패턴이 어떤지 등에 관하여 자유롭게 이야기해보세요.<br/><br/>
                        대화 후 매칭 희망시 ‘매칭요청’ 버튼을 클릭해주세요.<br/><br/>
                        운영정책에 관한 질문은 두리카 팀에 문의해주세요.<br/><br/>
                    </>}
                    {/*이용패턴이 어떤지, 주차장은 어디를 이용하면 되는지 등에 관하여 자유롭게 이야기해보세요!<br/><br/>*/}
                    {/*대화 후 매칭 희망시 오른쪽 매칭요청 버튼을 클릭해주세요.<br/><br/>*/}
                    {/*운영정책에 관한 질문은 두리카 팀에 문의해주세요.<br/><br/>*/}
                    <a target="_blank" href="https://copper-abrosaurus-467.notion.site/1a2c696f5199403a938b3b6abf3c1b64">[자주 묻는 질문] 보러가기</a>
                </p>
                {/*<p className="name">남길 한마디</p>*/}
                {/*<div className="input-box">*/}
                {/*    <input className="input" placeholder="안녕하세요 관심있는 차량이 있습니다" type="text" value={form.message} onChange={(e => onChange({id: 'message', value: e.target.value}))} />*/}
                {/*</div>*/}
                <div className="btn-box">
                    <div className="btn" onClick={onClose}>
                        취소
                    </div>
                    <div className="btn" onClick={handleClick}>
                        확인
                    </div>
                </div>
            </div>
        </_DMRequestDialog>
    </Dialog>
}

const _DMRequestDialog = styled.div`
  width: 296px;
    > .content {
      > .title {
        word-break: keep-all;
        display: block;
        font-size: 22px;
        border-bottom: 1px solid #303030;
        padding-top: 30px;
        padding-bottom: 14px;
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 20px;
      }

      
      > .desc {
        padding: 0 24px 12px;
        line-height: 18px;
        
        > a {
          text-decoration: underline;
        }
      }
      
      > .name {
        padding: 0 24px;
      }
      > .input-box {
        display: flex;
        box-sizing: border-box;
        //position: relative;
        border-radius: 6px;
        border: 1px solid rgba(212, 216, 229, 0.3);
        background-color: rgba(212, 216, 229, 0.12);
        display: flex;
        align-items: center;
        margin: 4px 24px 16px;
        //width: 100%;
        > .input {
          flex-grow: 1;
          background-color: rgba(0, 0, 0, 0);
          padding: 9px 11px 9px 11px;
          display: block;
          width: 100%;
          height: 36px;
          font-size: 14px;
          line-height: 16px;
          box-sizing: border-box;
          border: none;
        }
      }

      > .btn-box {
        display: flex;
        text-align: center;
        > .btn {
          flex-grow: 1;
          padding: 19px 0 18px;
          //border-bottom-left-radius: 12px;
          color: white;
          font-size: 18px;
          font-weight: 700;
          background-color: #959EA0;
          cursor: pointer;
        }
        > .btn:last-child {
          //border-bottom-right-radius: 12px;
          //border-bottom-left-radius: 0;
          background-color: #33CCC1;
        }
      }
    }
`
