import styled, { css } from "styled-components";
import { Drawer } from "@mui/material";
import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
    useMemo,
} from "react";
import { calcDistance, commaNum } from "../../../toolbox";
import { filter, find, findIndex, forEach, sortBy } from "lodash";
import { isCollision } from "../../../toolbox/calculate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { VehicleThumbnail } from "./VehicleThumbnail";
import { Swiper, SwiperSlide } from "swiper/react";
import FilterListIcon from "@mui/icons-material/FilterList";
import "swiper/swiper-bundle.css";
import qs from "query-string";

import SwiperCore, { Navigation } from "swiper";
import { useSelector } from "react-redux";
import { applyFilters, pathSelector } from "../../../toolbox/logic";
import {
    getResponseTimeTxt,
    getUseTypeTxt,
    manNum,
} from "../../../toolbox/format";
import Header from "../../../layout/Header";
import { Mannerbox } from "./Mannerbox";
import { VehicleWanted } from "./VehicleWanted";
import { FilterBtn, SearchBtn } from "../VehicleListPage";
import { Filter } from "@mui/icons-material";
import { GUEST_WANTED } from "../../../toolbox/guestTag";
import { sum } from "lodash";

SwiperCore.use([Navigation]);

const MyDrawer = styled(Drawer)`
    pointer-events: none;
`;

const _ThumbSwiper = ({ className, children }) => {
    return (
        <>
            <Swiper
                className={className}
                spaceBetween={10}
                slidesPerView="auto"
            >
                {children.map((element, idx) => {
                    return (
                        <SwiperSlide key={`thumb-${idx}`}>
                            {element}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </>
    );
};

const _MapSwiper = ({
    className,
    children,
    onChange,
    boundObjects,
    selected,
}) => {
    const navigationPrevRef = React.useRef();
    const navigationNextRef = React.useRef();
    const [mySwiper, setMySwiper] = useState(null);

    useEffect(() => {
        if (mySwiper && selected) {
            const idx = findIndex(
                boundObjects,
                (v) => v.mapId === selected.mapId
            );
            if (idx !== -1) {
                mySwiper.slideTo(Number(idx), 0);
            }
        }
    }, [mySwiper, selected, boundObjects]);

    return (
        <>
            {/*<div className="prev-btn" ref={navigationPrevRef}>{"<"}</div>*/}
            {/*<div className="next-btn" ref={navigationNextRef}>{">"}</div>*/}
            <div className={className}>
                <Swiper
                    // className={className}
                    slidesPerView="1"
                    spaceBetween={8}
                    onSlideChange={(swiper) => {
                        onChange(swiper.realIndex);
                    }}
                    // navigation
                    // navigation={{
                    //     prevEl: navigationPrevRef.current,
                    //     nextEl: navigationNextRef.current,
                    // }}
                    onSwiper={setMySwiper}
                    onInit={(swiper) => {
                        // swiper.params.navigation.prevEl = navigationPrevRef.current;
                        // swiper.params.navigation.nextEl = navigationNextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                >
                    {children.map((element, idx) => {
                        return (
                            <SwiperSlide key={`map-${idx}`}>
                                {element}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </>
    );
};

const MapSwiper = styled(_MapSwiper)`
    //width: 70vw;
    //padding-left: 10px;
    //padding-right: 10px;
    width: 100%;
    max-width: 400px;
    //max-width: 450px;
    z-index: 2;
    position: relative;
    overflow: hidden;
    > .swiper {
        width: 92%;
        overflow: visible;

        .swiper-slide {
            overflow: hidden;
        }
        .swiper-wrapper {
            align-items: center;
        }
        .swiper-button-prev {
            //bottom: -40px;
        }
        .swiper-button-next {
            //right: -40px;
        }
        .btn {
            position: absolute;
            left: 0;

            max-width: 60px;
            text-align: center;
            margin-left: 10px;
            margin-right: 10px;
            border-radius: 12px;
            background-color: white;
            padding: 6px;
            font-size: 20px;
            pointer-events: fill;
            cursor: pointer;
            flex-grow: 1;
        }
    }
`;

const NewUserPlate = ({ user }) => {
    const tokenInfo = useSelector(({ auth }) => auth.tokenInfo);
    const userInfo = useSelector(({ user }) => user.user);

    const emoji = useMemo(() => {
        if (tokenInfo?.id == user.id) {
            return "나";
        } else {
            return "게스트";
        }
    }, [tokenInfo?.id, user.id]);

    const userTags = useMemo(() => {
        const processIntroduce = (introduce) => {
            return introduce
                .split(",")
                .map((tag) => "#" + tag)
                .join(" ");
        };
        const processMatchingProfile = (matching_profile) => {
            if (matching_profile?.["원하는 차량"]) {
                return matching_profile["원하는 차량"].split(",").map((tag) => {
                    if (tag === "국산차" || tag === "외제차") {
                        return (
                            <div key={tag} className="response-time-tag">
                                {tag}
                            </div>
                        );
                    }
                    return (
                        <span key={tag} className="match-tag">
                            {tag}
                        </span>
                    );
                });
            }
            return "";
        };
        if (tokenInfo?.id == user.id) {
            if (
                userInfo?.matching_profile &&
                userInfo.matching_profile?.["원하는 차량"]
            ) {
                return processMatchingProfile(userInfo.matching_profile);
            }
            if (userInfo?.introduce) {
                return processIntroduce(userInfo.introduce);
            }
            return "";
        } else {
            if (
                user?.matching_profile &&
                user?.matching_profile?.["원하는 차량"]
            ) {
                return processMatchingProfile(user.matching_profile);
            }
            if (user?.introduce) {
                return processIntroduce(user.introduce);
            }
            return "";
        }
    }, [tokenInfo?.id, user.id, userInfo?.introduce, user?.introduce]);

    return (
        <_NewVehiclePlate guestid={true}>
            <div className="top">
                <div className="model">
                    {user?.nickname}
                    <span className="user-tag">{emoji}</span>
                </div>
                <div className="manner-liter right-absolute">
                    <Mannerbox liter={36} />
                </div>
            </div>
            <div className="bottom">
                <div className="image-box">
                    <NewVehicleThumbnail
                        image={{
                            url: user?.profile_image?.url
                                ? user.profile_image.url
                                : "/layout/char_doori.svg",
                        }}
                    />
                </div>
                <div className="vehicle">
                    <div className="guest-title">{userTags}</div>
                </div>
            </div>
        </_NewVehiclePlate>
    );
};
const NewVehiclePlate = ({ vehicle }) => {
    const tokenInfo = useSelector(({ auth }) => auth.tokenInfo);
    const m = useMemo(() => vehicle?.hostUser, [vehicle]);
    const v = useMemo(() => vehicle, [vehicle]);

    const tag = useMemo(() => {
        if (v?.hostContract?.useType === "TEMP") return "예비호스트";
        if (vehicle.guestUser) return "매칭완료";
        if (tokenInfo?.id === m?.id) return "나";
        return "호스트";
    }, [v?.hostContract?.useType, vehicle.guestUser, tokenInfo?.id, m?.id]);

    const isGhost = useMemo(() => {
        return vehicle.hostContract.isGhost;
    }, [vehicle.hostContract]);

    return (
        <_NewVehiclePlate
            guestid={vehicle?.guestId}
            matched={vehicle?.guestContract}
            isGhost={isGhost}
        >
            <div className="top">
                <div className="model">
                    <div className="model-text">
                        {v?.brand} {v?.model}
                    </div>
                    <div className="tag-box">
                        {tag !== "호스트" && (
                            <div className="user-tag">{tag}</div>
                        )}
                        <div
                            className={
                                isGhost ? "ghost-tag" : "response-time-tag"
                            }
                        >
                            {isGhost
                                ? "미응답호스트"
                                : getResponseTimeTxt(
                                      vehicle?.hostUser?.hostStat
                                  )
                                ? `평균응답${getResponseTimeTxt(
                                      vehicle?.hostUser?.hostStat
                                  )}`
                                : "응답시간보통"}
                        </div>
                        {v.hostContract.useTypes &&
                            v.hostContract.useTypes.map((u) => (
                                <div key={u.id} className="match-tag">
                                    {getUseTypeTxt(u.id)}
                                </div>
                            ))}
                    </div>
                    {/*{tag !== '예비호스트' && getUseTypeTxt(v.useType, v.useTypeDetail) && <span className="user-tag">{getUseTypeTxt(v.useType, v.useTypeDetail)}</span>}*/}
                </div>
                {tag === "나" && (
                    <Link
                        to={`/vehicle_modify?id=${v.hostContract.id}`}
                        className="right right-absolute"
                    >
                        <div className="my-modify">
                            {/*<img src="/my/edit_black.svg"/>*/}
                            수정하기
                        </div>
                    </Link>
                )}
            </div>
            <div className="bottom">
                <div className="image-box">
                    <NewVehicleThumbnail image={v?.thumbnail} />
                </div>
                <div className="vehicle">
                    <div className="user-box">
                        <div className="profile">
                            <div className="--image_wrap">
                                <img
                                    src={
                                        v?.hostUser?.profile_image?.url ??
                                        "/layout/char_doori.svg"
                                    }
                                ></img>
                            </div>
                            <div className="name">{v?.hostUser?.nickname}</div>
                        </div>
                        <div className="manner-liter">
                            <Mannerbox liter={36} />
                        </div>
                    </div>
                    {v?.hostContract?.dooriveTitle && (
                        <div className="title">
                            {v.hostContract.dooriveTitle}
                        </div>
                    )}
                    <div className="price">
                        {/*월 <span className="number">{manNum(v?.price)}</span>만원*/}
                        {v.eventPrice === false ? (
                            <>
                                <span
                                    style={{
                                        whiteSpace: "nowrap",
                                        textDecoration: "line-through",
                                    }}
                                >
                                    월{" "}
                                    {commaNum(
                                        Math.round((v?.price * 1.25) / 1000) *
                                            1000
                                    )}
                                    원
                                </span>{" "}
                                <span style={{ whiteSpace: "nowrap" }}>
                                    월 {commaNum(v?.price)}원
                                </span>
                                <br />
                                <span
                                    style={{
                                        color: "#2eb0e5",
                                        fontSize: "16px",
                                        wordBreak: "keep-all",
                                    }}
                                >
                                    추가 {manNum(v?.price * 0.2)}만원 캐시백
                                    (3개월 유지시)
                                </span>
                            </>
                        ) : (
                            <>
                                <span
                                    style={{
                                        whiteSpace: "nowrap",
                                        textDecoration: "line-through",
                                    }}
                                >
                                    월 {commaNum(v?.price)}원
                                </span>{" "}
                                <span style={{ whiteSpace: "nowrap" }}>
                                    월 {commaNum(v?.price * 0.9)}원
                                </span>
                                <br />
                                <span
                                    style={{
                                        color: "#2eb0e5",
                                        fontSize: "16px",
                                    }}
                                >
                                    첫 달 {commaNum(v?.price * 0.9 - 100000)}원
                                </span>
                            </>
                        )}
                        {/*{isRent ? <>*/}
                        {/*    월 <span className="number">{manNum(v?.priceSolo)}</span>만원*/}
                        {/*</>: <>*/}
                        {/*    월 <span className="number">{manNum(v?.price)}</span>만원*/}
                        {/*</>}*/}
                        {/*월 <span className="number">{manNum(v?.priceDuo)}</span>만원*/}
                    </div>
                </div>
            </div>
        </_NewVehiclePlate>
    );
};

const _NewVehiclePlate = styled.div`
    border-radius: 8px;
    background: white;
    padding: 10px 0 16px;
    pointer-events: fill;
    box-sizing: border-box;
    user-select: none;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);

    .right-absolute {
        z-index: 2;
        position: absolute;
        right: 8px;
        top: 0;
    }

    > .top {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 6px;
        border-bottom: 1px solid #f1f1f1;
        position: relative;

        .my-modify {
            border-radius: 4px;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
            padding: 2px 4px;
            &:hover {
                background: #f1f1f1;
            }
        }
        .model {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 100%;
            gap: 8px;

            .model-text {
                padding: 0 80px;
                overflow: hidden;
                word-break: keep-all;
                flex-grow: 2;
            }

            .tag-box {
                display: flex;
                gap: 6px;
                flex-wrap: wrap;
                align-items: center;
                font-size: 14px;
                flex-grow: 1;
                justify-content: center;
            }
        }

        .right {
            display: flex;
            gap: 4px;
            align-items: center;
            font-size: 14px;

            img {
                width: 18px;
                height: 18px;
            }
        }
    }

    .manner-liter {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 10px;
        font-weight: 500;
        color: #878787;
    }

    > .bottom {
        padding: 12px 16px 0;
        display: flex;
        > .vehicle {
            padding-left: 14px;
            width: 100%;
            display: flex;
            flex-direction: column;

            > .user-box {
                display: flex;
                justify-content: space-between;
                margin-bottom: 12px;
                > .profile {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 700;

                    > .--image_wrap {
                        width: 24px;
                        height: 24px;
                        min-width: 24px;
                        min-height: 24px;
                        margin-right: 6px;
                        > img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 999px;
                        }
                    }

                    > .name {
                    }
                }
            }

            .title {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 130%;
                color: #afafaf;
                height: 32px;
                overflow: hidden;
                word-break: keep-all;
            }

            .guest-title {
                display: flex;
                flex-wrap: wrap;
                gap: 4px;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: #afafaf;
                overflow: hidden;
                word-break: keep-all;
            }

            .price {
                margin-top: auto;
                text-align: right;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 100%;
                > .number {
                    color: #ff6754;
                    margin-right: 2px;
                }
            }
        }
    }

    .match-tag {
        font-weight: 500;
        font-size: 12px;
        padding: 2px 4px;
        border-radius: 4px;
        white-space: nowrap;
        color: #f16767;
        background: #ffeaea;
        box-sizing: border-box;
    }

    .response-time-tag {
        font-weight: 500;
        font-size: 12px;
        padding: 2px 4px;
        border-radius: 4px;
        white-space: nowrap;
        color: #4dc361;
        background: #e0ffe0;
        box-sizing: border-box;
    }

    .ghost-tag {
        font-weight: 500;
        font-size: 12px;
        padding: 2px 4px;
        border-radius: 4px;
        white-space: nowrap;
        box-sizing: border-box;
        color: white;
        background: black;
    }

    .user-tag {
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        padding: 3px 5px;
        margin-left: 6px;
        border-radius: 4px;
        white-space: nowrap;
    }
    ${(props) =>
        props.guestid
            ? css`
                  .user-tag {
                      color: #698aff;
                      background: #ecf1ff;
                  }
              `
            : props.matched
            ? css`
                  .user-tag {
                      color: #4dc361;
                      background: #e0ffe0;
                  }
              `
            : css`
                  .user-tag {
                      color: #f16767;
                      background: #ffeaea;
                  }
              `}
`;

const NewVehicleThumbnail = styled(VehicleThumbnail)`
    border: none;
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    margin: 0;
    padding: 0;
    border-radius: 8px;
`;

const getVehicleLink = (v) => {
    const mode = v.vehicleType === "";
    const params = {
        // mode: v.vehicleType,
        mode: "doori",
    };
    return `/vehicle/${v.id}?${qs.stringify(params)}`;
};
const VehicleMarker = ({ className, id, v, selected, onClick }) => {
    const link = useRef();
    const tokenInfo = useSelector(({ auth }) => auth.tokenInfo);
    const userInfo = useSelector(({ user }) => user.user);

    const isGhost = useMemo(() => {
        return v.hostContract.isGhost;
    }, [v.hostContract]);

    const emoji = useMemo(() => {
        if (v?.guestUser) {
            return "매칭완료";
        } else if (v?.hostUser?.id === tokenInfo?.id) {
            return "나";
        } else if (v?.guestId) {
            return "게스트";
        } else if (v?.hostContract?.useType === "TEMP") {
            return "예비호스트";
        } else if (isGhost) {
            return "미응답호스트";
        } else {
            return "호스트";
        }
    }, [
        v?.guestUser,
        v?.hostUser?.id,
        tokenInfo?.id,
        v?.guestId,
        v?.hostContract?.useType,
    ]);

    const model = useMemo(() => {
        if (["기아", "현대", "제네시스"].includes(v?.brand)) {
            return v.model;
        } else {
            if (v.brand) {
                return `${v?.brand} ${v?.model}`;
            }
            return v.model;
        }
    }, [v?.brand, v?.model]);

    const handleClick = useCallback(() => {
        if (selected) {
            link.current.click();
        } else {
            onClick();
        }
    }, [selected, onClick]);

    return (
        <div>
            <_VehicleMarker
                matched={v?.guestContract}
                guestid={v?.guestId}
                isGhost={isGhost}
                selected={selected}
                id={id}
                onClick={handleClick}
            >
                <span
                    className={"user-tag"}
                    selected={selected}
                    guestid={v?.guestId}
                >
                    {emoji}
                </span>
                {model}
                {v.eventPrice === false ? (
                    <span> {manNum(v.price)}만원 </span>
                ) : (
                    <>
                        <span> {manNum(v.price * 0.9)}만원 </span>
                        <span style={{ textDecoration: "line-through" }}>
                            {manNum(v.price)}만원
                        </span>
                    </>
                )}
                <Link to={getVehicleLink(v)} ref={link} />
            </_VehicleMarker>
        </div>
    );
};

const NewUserMarker = ({ className, id, u, selected, onClick }) => {
    const link = useRef();
    const tokenInfo = useSelector(({ auth }) => auth.tokenInfo);

    const emoji = useMemo(() => {
        if (tokenInfo?.id == u.id) {
            return "나";
        } else {
            return "게스트";
        }
    }, [tokenInfo?.id, u.id]);

    const content = useMemo(() => {
        return u.nickname;
    }, [u.nickname]);

    const handleClick = useCallback(() => {
        if (selected) {
            link.current.click();
        } else {
            onClick();
        }
    }, [selected, onClick]);

    return (
        <div>
            <_VehicleMarker
                guestid={true}
                selected={selected}
                id={id}
                onClick={handleClick}
            >
                <span className="user-tag" selected={selected} guestid={true}>
                    {emoji}
                </span>
                {content}
                <Link to={"/user/" + u.id} ref={link} />
            </_VehicleMarker>
        </div>
    );
};

const _VehicleMarker = styled.div`
    //box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: white;
    color: black;
    padding: 8px;
    border-radius: 4px;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2));
    white-space: pre;
    //box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, .2), 0px 4px 5px 0px rgba(0, 0, 0, .14), 0px 1px 10px 0px rgba(0, 0, 0, .12);
    cursor: pointer;

    font-weight: 700;
    font-size: 14px;
    line-height: 130%;

    &:hover {
        background: black;
        color: white;
        //border: 1px solid black;
        //background: #195F56;

        //&::before {
        //  border-top: 10px solid #195F56;
        //  border-top: 10px solid white;
        //}
    }

    ${(props) =>
        props.selected &&
        css`
            background: black;
            color: white;
            //box-shadow: 0 0 0 1px blue inset;
        `}

    &::before {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -10px;
        width: 0;
        height: 0;
        border-top: 10px solid white;
        border-bottom: none;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;

        ${(props) =>
            props.selected &&
            css`
                border-top: 10px solid black;
            `}
    }

    //&::after {
    //  content: '';
    //  display: block;
    //  position: absolute;
    //  left: 50%;
    //  transform: translateX(-50%);
    //  bottom: -11px;
    //  width: 12px;
    //  height: 4px;
    //  border-radius: 100%;
    //  z-index: 0;
    //  background: rgba(0, 0, 0, 0.2);
    //}

    > .user-tag {
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        padding: 3px 5px;
        margin-right: 6px;
        border-radius: 4px;
    }

    ${(props) => {
        if (props.isGhost) {
            return css`
                > .user-tag {
                    color: white;
                    background: black;
                }
            `;
        } else if (props.guestid) {
            return css`
                > .user-tag {
                    color: #698aff;
                    background: #ecf1ff;
                }
                &:hover {
                    > .user-tag {
                        background: #383d4b;
                        color: #7795ff;
                    }
                }
            `;
        } else if (props.matched) {
            return css`
                > .user-tag {
                    color: #4dc361;
                    background: #e0ffe0;
                }
                &:hover {
                    > .user-tag {
                        background: #384b3d;
                        color: #8cff9f;
                        //color: #7795FF;
                    }
                }
            `;
        } else {
            return css`
                > .user-tag {
                    color: #f16767;
                    background: #ffeaea;
                }
                &:hover {
                    > .user-tag {
                        background: #443434;
                    }
                }
            `;
        }
    }}
    ${(props) =>
        props.selected &&
        css`
            ${(props) =>
                props.guestid
                    ? css`
                          > .user-tag {
                              background: #383d4b;
                              color: #7795ff;
                          }
                      `
                    : props.matched
                    ? css`
                          > .user-tag {
                              background: #384b3d;
                              color: #8cff9f;
                              //color: #7795FF;
                          }
                      `
                    : css`
                          > .user-tag {
                              background: #443434;
                          }
                      `}
        `}
`;

const _Login = styled.div`
    user-select: none;
    display: flex;
    align-items: center;
    //max-width: 410px;
    //width: calc(100% - 80px);
    word-break: keep-all;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    position: absolute;
    top: 60px;
    //top: 104px;
    z-index: 3;
    cursor: pointer;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;

    color: white;
    padding: 8px 16px;
    background: #5ecdc0;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    justify-content: space-between;

    > .tag-view {
        min-width: 210px;
        max-width: 210px;
        width: 210px;
        overflow: hidden;
        text-align: left;

        background: linear-gradient(
            90deg,
            #ffffff 80%,
            rgba(255, 255, 255, 0) 95%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    > .edit {
        padding: 4px 8px;
        border: 1px solid #ffffff;
        border-radius: 999px;
        font-weight: 600;
        font-size: 13px;
        line-height: 130%;
        color: #ffffff;
    }
`;

const calcPosition = (positions, newPosition) => {
    let longitude = newPosition.longitude;
    let latitude = newPosition.latitude;
    let offsetX = 0;
    let offsetY = 0;
    while (true) {
        const overlap = find(
            positions,
            (p) =>
                p.latitude === latitude &&
                p.longitude === longitude &&
                p.offsetX === offsetX &&
                p.offsetY === offsetY
        );
        if (overlap) {
            offsetX = offsetX + 1;
            offsetY = offsetY + 1;
        } else {
            return { longitude, latitude, offsetX, offsetY };
        }
    }
};

const _VehicleMap = ({
    className,
    open,
    onClose,
    vehicles,
    filteredVehicles,
    guestTags,
    setMapBound,
    filters,
    onFilterChange,
    guestList,
}) => {
    const mapRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();

    const pathInfo = useSelector(({ path }) => path);
    const tokenInfo = useSelector(({ auth }) => auth.tokenInfo);
    const userInfo = useSelector(({ user }) => user.user);

    const [kakaoObjects, setKakaoObjects] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [map, setMap] = useState();
    const [selected, setSelected] = useState();
    const [guestVisible, setGuestVisible] = useState(guestList ? true : false);
    const [matchedVisible, setMatchedVisible] = useState(true);
    const path = useMemo(
        () => pathSelector(userInfo, pathInfo),
        [userInfo, pathInfo]
    );

    const guestTagCount = useMemo(
        () => sum(GUEST_WANTED.map((w) => w.tags.length)),
        []
    );
    const _filters = useMemo(
        () => (filters ? filters.split(",") : []),
        [filters]
    );
    const isFiltered = useMemo(
        () => _filters.length && _filters.length < guestTagCount,
        [filters, guestTagCount]
    );

    const createOverlay = useCallback((item, zIndex, points) => {
        const kakao = window.kakao;
        const point = calcPosition(points, item);
        return {
            overlay: new kakao.maps.CustomOverlay({
                zIndex,
                position: new kakao.maps.LatLng(
                    point.latitude || item.lat,
                    point.longitude || item.lng
                ),
                content: document.getElementById(item.mapId),
                yAnchor: 1.2 + point.offsetY * 0.8,
            }),
            point,
        };
    }, []);

    useEffect(() => {
        if (vehicles && kakaoObjects) {
            const _filteredVehicles = applyFilters(
                vehicles,
                filters.split(",")
            );
            const _filteredFilteredVehicles = matchedVisible
                ? applyFilters(filteredVehicles, filters.split(","))
                : [];
            kakaoObjects.forEach((k) => {
                const visible =
                    _filteredVehicles.some(
                        (v) => v.mapId === k.getContent().id
                    ) ||
                    _filteredFilteredVehicles.some(
                        (v) => v.mapId === k.getContent().id
                    ) ||
                    (guestVisible && k.getContent().id.startsWith("u"));
                if (visible && !k.getMap()) {
                    k.setMap(map);
                } else if (!visible && k.getMap()) {
                    k.setMap(null);
                }
            });
        }
    }, [
        kakaoObjects,
        vehicles,
        filteredVehicles,
        guestTags,
        filters,
        guestVisible,
        matchedVisible,
    ]);

    const createOverlays = useCallback(
        (items, zIndex) => {
            return items.reduce(
                (acc, item) => {
                    const ref = document.getElementById(item.mapId);
                    if (ref) {
                        const { overlay, point } = createOverlay(
                            item,
                            zIndex,
                            acc.points
                        );
                        acc.overlays.push(overlay);
                        acc.points.push(point);
                    }
                    return acc;
                },
                { overlays: [], points: [] }
            );
        },
        [createOverlay]
    );

    const initMap = useCallback(() => {
        if (!mapRef.current || map || !window?.kakao) {
            return;
        }
        const kakao = window.kakao;
        const mapContainer = mapRef.current;
        const defaultLatlng = new kakao.maps.LatLng(
            path?.latitude ?? 37.4923585043421,
            path?.longitude ?? 127.030707488212
        );
        const mapOption = {
            center: defaultLatlng,
            level: 5,
        };

        const vehicleOverlays = createOverlays(vehicles, 2);
        const filteredVehicleOverlays = createOverlays(filteredVehicles, 2);

        let users = guestTags;
        const vehicle = find(vehicles, (v) => tokenInfo?.id == v.hostUser.id);
        if (vehicle) {
            users = users.filter((v) => v.mapId != `u${tokenInfo?.id}`);
        }
        const userOverlays = createOverlays(users, 1);

        const allOverlays = [
            ...vehicleOverlays.overlays,
            ...filteredVehicleOverlays.overlays,
            ...userOverlays.overlays,
        ];

        let m = new kakao.maps.Map(mapContainer, mapOption);
        setMap(m);

        var zoomControl = new kakao.maps.ZoomControl();
        m.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

        allOverlays.forEach((overlay) => overlay.setMap(m));

        setKakaoObjects(allOverlays);
    }, [
        mapRef,
        map,
        path,
        vehicles,
        filteredVehicles,
        guestTags,
        tokenInfo,
        createOverlays,
    ]);

    useEffect(() => {
        if (open && !map) {
            initMap();
        }
    }, [open, map, initMap]);

    useEffect(() => {
        if (map && kakaoObjects.length) {
            onChangeMapBounds();
        }
    }, [map, kakaoObjects]);

    const onFocus = useCallback(
        (objectId) => {
            kakaoObjects.forEach((k) => {
                try {
                    let kId = k.getContent().id;
                    let zIndex =
                        kId === objectId ? 3 : kId.startsWith("v") ? 2 : 1;
                    k.setZIndex(zIndex);
                } catch (e) {
                    console.error("Error setting zIndex:", e);
                }
            });
        },
        [kakaoObjects]
    );

    useEffect(() => {
        if (selected && window.history.replaceState) {
            window.history.replaceState(
                null,
                null,
                "#" + (selected.mapId ?? selected.id)
            );
        }
    }, [selected]);

    const moveToCurrentLocation = useCallback(() => {
        if (navigator.geolocation && map) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    let latlng = new window.kakao.maps.LatLng(
                        position.coords.latitude,
                        position.coords.longitude
                    );
                    map.panTo(latlng);
                },
                (error) => console.error("Geolocation error:", error),
                { enableHighAccuracy: false, maximumAge: 0, timeout: Infinity }
            );
        } else {
            console.log(
                "GPS를 지원하지 않습니다: 검색 또는 화면을 움직여서 위치를 찾으십시오"
            );
        }
    }, [map]);

    useEffect(() => {
        if (location.hash && map && vehicles.length > 0 && !loaded) {
            setLoaded(true);
            const hashId = location.hash.slice(1);
            const vehicle = vehicles.find((v) => v?.mapId === hashId);
            const user = guestTags.find((u) => u?.mapId === hashId);

            if (vehicle || user) {
                const item = vehicle || user;
                let latlng = new window.kakao.maps.LatLng(
                    item.latitude || item.lat,
                    item.longitude || item.lng
                );
                map.panTo(latlng);
                onChangeMapBounds();
                onFocus(item.mapId);
                setSelected(item);
            }
        }
    }, [vehicles, map, guestTags, location.hash, loaded, onFocus]);

    const onChangeMapBounds = useCallback(() => {
        if (map) {
            setSelected(null);
        }
    }, [map]);

    useEffect(() => {
        if (map) {
            const kakao = window.kakao;
            kakao.maps.event.addListener(
                map,
                "center_changed",
                onChangeMapBounds
            );
            kakao.maps.event.addListener(map, "click", () => setSelected(null));

            return () => {
                kakao.maps.event.removeListener(
                    map,
                    "center_changed",
                    onChangeMapBounds
                );
                kakao.maps.event.removeListener(map, "click", () =>
                    setSelected(null)
                );
            };
        }
    }, [map, onChangeMapBounds]);

    return (
        <>
            <MyDrawer
                open={open}
                anchor="bottom"
                hideBackdrop={true}
                ModalProps={{ keepMounted: true }}
            >
                <div className={className}>
                    <div className="header-wrap">
                        {/* <Header onLogo={() => onClose()} /> */}
                        <Header onLogo={() => test()} />
                    </div>
                    <div className="map" ref={mapRef} />
                    <a
                        onClick={moveToCurrentLocation}
                        style={{ cursor: "pointer" }}
                    >
                        <MoveToCurrent />
                    </a>

                    <div className="filter-btn-wrap">
                        <FilterBtn
                            selected={guestVisible}
                            onClick={() => setGuestVisible(!guestVisible)}
                        >
                            <span>게스트 표시</span>
                        </FilterBtn>
                        <FilterBtn
                            selected={matchedVisible}
                            onClick={() => setMatchedVisible(!matchedVisible)}
                        >
                            <span>매칭된 차량</span>
                        </FilterBtn>
                        <FilterBtn
                            selected={isFiltered}
                            onClick={() => setFilterOpen(true)}
                        >
                            <FilterListIcon fontSize={"14px"} />
                            <span>차량 필터</span>
                        </FilterBtn>
                    </div>

                    <div className="bottom-box">
                        <div className="close-btn" onClick={onClose}>
                            <span>리스트로 보기</span>
                        </div>
                    </div>
                    {selected && (
                        <div className="selected-wrap">
                            {selected.type === "user" && (
                                <Link
                                    style={{
                                        flexGrow: "1",
                                        maxWidth: "450px",
                                        margin: "0 16px",
                                    }}
                                    key={selected?.mapId}
                                    to={"/user/" + selected.id}
                                >
                                    <NewUserPlate user={selected} />
                                </Link>
                            )}
                            {selected.type === "vehicle" && (
                                <Link
                                    style={{
                                        flexGrow: "1",
                                        maxWidth: "450px",
                                        margin: "0 16px",
                                    }}
                                    key={selected?.mapId ?? selected?.id}
                                    to={getVehicleLink(selected)}
                                >
                                    <NewVehiclePlate vehicle={selected} />
                                </Link>
                            )}
                            {selected.type === "matched" && (
                                <Link
                                    style={{
                                        flexGrow: "1",
                                        maxWidth: "450px",
                                        margin: "0 16px",
                                    }}
                                    key={selected?.mapId ?? selected?.id}
                                    to={getVehicleLink(selected)}
                                >
                                    <NewVehiclePlate vehicle={selected} />
                                </Link>
                            )}
                        </div>
                    )}
                    {vehicles &&
                        vehicles.map((v) => (
                            <VehicleMarker
                                id={v.mapId}
                                key={`vehicle-${v.mapId}`}
                                v={v}
                                selected={selected?.mapId === v.mapId}
                                onClick={() => {
                                    onFocus(v.mapId);
                                    setSelected(v);
                                }}
                            />
                        ))}
                    {filteredVehicles &&
                        filteredVehicles.map((v) => (
                            <VehicleMarker
                                id={v.mapId}
                                key={`filtered-${v.mapId}`}
                                v={v}
                                selected={selected?.mapId === v.mapId}
                                onClick={() => {
                                    onFocus(v.mapId);
                                    setSelected(v);
                                }}
                            />
                        ))}
                    {guestTags &&
                        guestTags.map((u) => {
                            return (
                                <NewUserMarker
                                    id={u.mapId}
                                    key={`user-${u.mapId}`}
                                    u={u}
                                    selected={selected?.mapId === u.mapId}
                                    onClick={() => {
                                        onFocus(u.mapId);
                                        setSelected(u);
                                    }}
                                />
                            );
                        })}
                </div>
            </MyDrawer>
            <VehicleWanted
                tags={filters}
                setTags={(v) => onFilterChange({ id: "filters", value: v })}
                open={filterOpen}
                onClose={() => setFilterOpen(false)}
            />
        </>
    );
};

// export default React.memo(VehicleMap);

export const VehicleMap = styled(_VehicleMap)`
    pointer-events: fill;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    > .map {
        box-shadow: 0 -6px 6px -3px rgba(0, 0, 0, 0.07);
        //box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.2);
        width: 100%;
        height: calc(100% - 50px);
    }

    .header-wrap {
        display: flex;
        justify-content: center;
    }

    .filter-btn-wrap {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        gap: 8px;
        position: absolute;
        top: 52px;
        padding-left: 16px;
        padding-right: 42px;
        z-index: 2;

        > :last-child {
            margin-left: auto;
            border-radius: 12px;
        }

        // div {
        //     margin: unset;
        // }
    }

    .prev-btn {
        background-color: white;
        padding: 14px;
        position: absolute;
        left: calc(max(50vw - 300px, 5px));
        z-index: 20;
        cursor: pointer;
        pointer-events: fill;
        border-radius: 12px;
        border: 1px solid black;
        bottom: -45px;
    }
    .next-btn {
        background-color: white;
        padding: 14px;
        position: absolute;
        right: calc(max(50vw - 300px, 5px));
        z-index: 20;
        cursor: pointer;
        pointer-events: fill;
        border-radius: 12px;
        border: 1px solid black;
        bottom: -45px;
    }

    .selected-wrap {
        max-height: 200px;
        pointer-events: none;
        //z-index: 0;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        bottom: 88px;
        z-index: 50;
        left: 50%;
        transform: translateX(-50%);

        .swiper-button-disabled {
            display: none;
        }
    }

    > .bottom-box {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        z-index: 50;
        left: 50%;
        transform: translateX(-50%);

        > .close-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 999px;
            background: #5ecdc0;
            //background: black;
            //border: 1px solid white;
            color: white;
            cursor: pointer;
            padding: 11px 16px;
            white-space: nowrap;
            margin-right: 10px;
        }

        > :last-child {
            margin-right: 0;
        }
    }
`;

const MoveToCurrent = styled.span`
    position: absolute;
    top: 242px;
    right: 1px;
    width: 38px;
    height: 38px;
    z-index: 2;
    background-image: url("/map/current_location.svg");
    &:active {
        background-image: url("/map/current_location_mouseover.svg");
    }
`;
