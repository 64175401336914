import {useEffect} from "react";
import {FormComponent} from "../vehicle/VehicleAdd2/FormComponent";
import {useForm} from "../../toolbox";
import moment from "moment-timezone";

const HostEarn = () => {
    const {form, onChange} = useForm({
        before: '500000',
    });

    useEffect(() => {
        const v = Number(form.before);
        const 선납금포함월요금 = Math.round(v * 0.9);
        const 호스트수수료공제후월수익 = Math.round(선납금포함월요금 * 0.93 / 10) * 10;

        const 첫달할인후월요금 = 선납금포함월요금 - 100000;
        const 첫달월수익 = Math.round(첫달할인후월요금 * 0.93 / 10) * 10;
        onChange([
            {
                id: 'result2',
                value: 선납금포함월요금,
            },
            {
                id: 'result3',
                value: 호스트수수료공제후월수익,
            },
            {
                id: 'result4',
                value: 첫달할인후월요금,
            },
            {
                id: 'result5',
                value: 첫달월수익,
            },

        ]);
    }, [form.before]);

    return <div style={{padding: '20px', gap:'8px', display: 'flex', flexDirection: 'column'}}>
        <FormComponent type="tel" id="before" title="할인전월요금" form={form} onChange={onChange}/>
        <FormComponent type="tel" id="result2" title="10% 할인후월요금" form={form} onChange={onChange} disabled/>
        <FormComponent type="tel" id="result3" title="위 금액에서 수수료 7% 공제" form={form} onChange={onChange} disabled/>
        <FormComponent type="tel" id="result4" title="10% 할인 + 첫달 10만원 할인후 월요금" form={form} onChange={onChange} disabled/>
        <FormComponent type="tel" id="result5" title="위 금액에서 수수료 7% 공제" form={form} onChange={onChange} disabled/>
    </div>
}

const Commission = () => {
    const {form, onChange} = useForm({
        before: '500000',
    });

    useEffect(() => {
        const v = Number(form.before);
        const result = Math.round(v * 0.93 / 10) * 10;
        onChange([
            {
                id: 'result2',
                value: result,
            },

        ]);
    }, [form.before]);

    return <div style={{padding: '20px', gap:'8px', display: 'flex', flexDirection: 'column'}}>
        <FormComponent type="tel" id="before" title="수수료공제전" form={form} onChange={onChange}/>
        <FormComponent type="tel" id="result2" title="7% 수수료공제후" form={form} onChange={onChange} disabled/>
    </div>
}
const ForGuest = () => {
    const {form, onChange} = useForm({
        before: '500000',
    });

    useEffect(() => {
        const v = Number(form.before);
        const result2 = Math.round(v * 3);
        const result3 = Math.round(v * 0.9 - 100000);
        const result4 = Math.round(v * 0.9);
        onChange([
            {
                id: 'result2',
                value: result2,
            },
            {
                id: 'result3',
                value: result3,
            },
            {
                id: 'result4',
                value: result4,
            },
        ]);
    }, [form.before]);

    return <div style={{padding: '20px', gap:'8px', display: 'flex', flexDirection: 'column'}}>
        <FormComponent type="tel" id="before" title="할인전월요금" form={form} onChange={onChange}/>
        <FormComponent type="tel" id="result2" title="보증금" form={form} onChange={onChange} disabled/>
        <FormComponent type="tel" id="result3" title="첫달월요금 (10% 할인 + 10만원 할인)" form={form} onChange={onChange} disabled/>
        <FormComponent type="tel" id="result4" title="이후월요금 (10% 할인)" form={form} onChange={onChange} disabled/>
    </div>
}

const OverDue = () => {
    const d = moment.tz('Asia/Seoul').date();
    const {form, onChange} = useForm({
        before: '500000',
        reqDate: d >= 25 ? moment.tz('Asia/Seoul').format('YYYY-MM-25'): moment.tz('Asia/Seoul').subtract(1, 'month').format('YYYY-MM-25'),
        nowDate: moment.tz('Asia/Seoul').format('YYYY-MM-DD'),
    });

    useEffect(() => {
        if (form.before && form.reqDate && form.nowDate) {
            const v = Number(form.before);
            const req = moment.tz(form.reqDate, 'Asia/Seoul');
            const now = moment.tz(form.nowDate, 'Asia/Seoul');

            let diff = now.diff(req);
            let duration = moment.duration(diff);
            let overdue = Math.floor(duration.asDays());

            // let overPrice = Math.round(overdue * 10000 + (v * (0.2 / 365 * overdue)));
            let overPrice = Math.round(v * (0.2 / 365 * overdue));

            onChange([
                {
                    id: 'result1',
                    value: overdue,
                },
                {
                    id: 'result2',
                    value: overPrice,
                },
                {
                    id: 'result3',
                    value: v + overPrice,
                }]);
        }
    }, [form.before, form.reqDate, form.nowDate]);

    return <div style={{padding: '20px', gap:'8px', display: 'flex', flexDirection: 'column'}}>
        <FormComponent type="tel" id="before" title="청구금액" form={form} onChange={onChange}/>
        <FormComponent type="date" id="reqDate" title="청구일" form={form} onChange={onChange}/>
        <FormComponent type="date" id="nowDate" title="납부일" form={form} onChange={onChange}/>
        <FormComponent type="tel" id="result1" title="연체일수" form={form} onChange={onChange} disabled/>
        <FormComponent type="tel" id="result2" title="가산금+지연이자" form={form} onChange={onChange} disabled/>
        <FormComponent type="tel" id="result3" title="미납요금" form={form} onChange={onChange} disabled/>
    </div>
}

const CalcPage = () => {
    return <div style={{gap:'8px', display: 'flex', flexDirection: 'column'}}>
        <h1 style={{padding: "12px", marginTop: '20px', marginBottom: '20px'}}>관리자용 계산기 페이지~~</h1>

        <div>
            <h3 style={{padding: '8px'}}>호스트 수익 계산</h3>
            <HostEarn/>
        </div>

        <div>
            <h3 style={{padding: '8px'}}>7% 수수료 계산</h3>
            <Commission/>
        </div>

        <div>
            <h3 style={{padding: '8px'}}>게스트 요금 계산</h3>
            <ForGuest/>
        </div>

        <div>
            <h3 style={{padding: '8px'}}>연체 요금 계산</h3>
            <OverDue/>
        </div>
    </div>
}

export default CalcPage;
