import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import qs from 'query-string';
import {kakaoLogin, kakaoRestLogin} from "../../toolbox/kakao";
import {useLocation, useNavigate} from "react-router-dom";
import styled, {css} from "styled-components";
import {parseJwt, useForm} from "../../toolbox";
import {authAction} from "../../redux/authReducer";
import {SIGN_UP_POLICY, SIGN_UP_POLICY_ID} from "./SignUpPolicy";
import {useMutation} from "@apollo/client";
import {UPDATE_USER} from "../../query/userQuery";
import moment from "moment-timezone";
import {userAction} from "../../redux/userReducer";
import {toNumberPlateFormat, toPhoneFormat} from "../../toolbox/format";
import {loadingAction} from "../../redux/loadingReducer";
import LoadingIndicator from "../../layout/LoadingIndicator";
import {openAddress} from "../../toolbox/logic";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";

const PageWrap = styled.div`
  padding: 20px;
  text-align: center;
`
const Login = styled.div`
    cursor: pointer;
`

const Agreement = styled.div`
  padding: 20px;
  > h3 {
    margin-bottom: 20px;
  }
  > textarea {
    width: 100%;
  }
  > .button-wrap {
    cursor: pointer;
    margin-top: 20px;
    padding: 8px;
    > .submit {
      border-radius: 8px;
      height: 30px;
      line-height: 30px;
      background-color: black;
      color: white;
    }
  }
`

export function LoginCallbackPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const prev = parsed?.state ?? '/';

    const {form, onChange} = useForm({
        nickname: '',
        phone: '',
        address: '',
        detailAddress: '',
        lng: null,
        lat: null,
    });
    const [token, setToken] = useState(null);
    const [agreement, setAgreement] = useState(false);
    const [user, setUser] = useState(null);
    const [updateUser] = useMutation(UPDATE_USER);

    useEffect(() => {
        if (parsed.code) {
            kakaoRestLogin(parsed.code, handleLogin)
        }
    }, []);


    useEffect(() => {
        dispatch(loadingAction.loading(false));
    }, [location]);

    const handleLogin = (jwt, userInfo) => {
        if (userInfo?.agreements?.[SIGN_UP_POLICY_ID]) {
            dispatch(authAction.update(jwt))
            navigate(prev);
        } else {
            dispatch(authAction.logout());
            dispatch(userAction.logout());
            setToken(jwt);
            setUser(userInfo);
            if (userInfo?.nickname) {
                onChange({id: 'nickname', value: userInfo.nickname});
            }
            if (userInfo?.phone) {
                onChange({id: 'phone', value: userInfo.phone});
            }
            if (userInfo?.address) {
                onChange({id: 'address', value: userInfo.address.split('\t')[0]});
                onChange({id: 'detailAddress', value: userInfo.address.split('\t')[1]});
            }
            if (userInfo?.lng) {
                onChange({id: 'lng', value: userInfo.lng});
            }
            if (userInfo?.lat) {
                onChange({id: 'lat', value: userInfo.lat});
            }
        }
    }

    const handleAgreement = async () => {
        const nickname = form.nickname;
        const phone = form.phone;
        const address = form.address;
        const detailAddress = form.detailAddress;
        if (!agreement) {
            alert('필수 약관에 동의해야합니다');
            return;
        }
        if (!nickname) {
            alert('닉네임을 입력해주세요');
            return;
        }
        if (!phone) {
            alert('연락처를 입력해주세요');
            return;
        }
        if (!address) {
            alert('주소를 입력해주세요');
            return;
        }

        dispatch(loadingAction.loading(true));
        window.fbq('track', 'CompleteRegistration');
        dispatch(authAction.update(token))
        let agreements = user.agreements ?? {};
        agreements[SIGN_UP_POLICY_ID] = moment.tz('Asia/Seoul');

        const payload = {
            variables: {
                id: parseJwt(token).id,
                data: {
                    agreements,
                    nickname,
                    phone,
                    address: `${address}\t${detailAddress}`,
                    lng: form.lng,
                    lat: form.lat,
                }
            }
        };

        await updateUser(payload);
        try {
            await axios.post(SERVER_ADDRESS + '/api/notifications/signup', {}, {});
        } catch (e) {

        }
        dispatch(loadingAction.loading(false));
        navigate(prev);
    }

    return (<PageWrap>
        {token ?
            <Agreement>
                <h3>서비스 이용 약관</h3>
                <textarea rows="10" disabled>{SIGN_UP_POLICY}</textarea>
                <input type='checkbox' checked={agreement} onChange={() => setAgreement((prev) => !prev)}/> 위 내용을 모두 읽고 동의합니다.
                <div style={{textAlign: 'left', marginTop: '12px'}}>
                    <FormComponent type="text" id="nickname" title="닉네임" placeholder="홍길동" onChange={onChange} form={form} />
                    <FormComponent id="address" title="거주지 주소" form={form} onChange={onChange} />
                    <FormComponent id="detailAddress" title="상세주소" form={form} onChange={onChange} />
                    <FormComponent type="tel" id="phone" title="연락처" placeholder="010-1234-5678" onChange={onChange} form={form} />
                </div>
                <div className="button-wrap" onClick={handleAgreement}>
                    <div className="submit">완료하기</div>
                </div>
            </Agreement>:
            <LoadingIndicator isVisible={true} />
        }
        <br/>
    </PageWrap>);
};

const FormComponent = ({id, title, form, onChange, onClick, ...props}) => {
    const InputComponent = () => {
        if (id === 'phone') {
            return <input {...props} id={id} value={form[id]} onChange={(e) => onChange({id: e.target.id, value: toPhoneFormat(e.target.value)})}/>;
        } else if (id === 'numberPlate') {
            return <input {...props} id={id} value={form[id]} onChange={(e) => onChange({id: e.target.id, value: toNumberPlateFormat(e.target.value)})}/>;
        } else if (id === 'address') {
            return <>
                <span className="search" {...props} id={id}><img src="/vehicle/search.svg"/>{form[id]}</span>
            </>;
        }
        return <input id={id} value={form[id]} {...props} onChange={(e) => onChange({id: e.target.id, value: e.target.value})}/>;
    }

    return <_FormComponent {...props} onClick={() => {
      if (id === 'address') {
        openAddress((address, longitude, latitude) => {
          onChange([
            {id: 'address', value: address},
            {id: 'lng', value: longitude},
            {id: 'lat', value: latitude},
          ]);
        })
      }
    }}>
        {title && <div className="title">{title}</div>}
        {/*<div className="title">{title}</div>*/}
        <div className="content" onClick={onClick}>
            <div className="text-input">
                {InputComponent()}
                {/*<input id={id} type="text" value={form[id]} onChange={(e) => onChange({id: e.target.id, value: e.target.value})}/>*/}
            </div>
        </div>
    </_FormComponent>
}

const _FormComponent = styled.div`
  //padding: 6px 12px;
  > .title {
    font-weight: 700;
    //font-size: 20px;
    line-height: 120%;

    padding-left: 4px;
    padding-top: 12px;
    padding-bottom: 4px;
    font-size: 16px;
  }

  > .title-small {
    font-weight: 700;
    //font-size: 20px;
    line-height: 100%;

    padding-left: 4px;
    padding-top: 12px;
    padding-bottom: 4px;
    font-size: 16px;
  }

  > .content {

    > .image {
      max-width: 100%;
      max-height: 200px;
      img {
        max-width: 100%;
        max-height: 200px;
        object-fit: contain;
      }
    }


    > .text-input {
      position: relative;
      border-bottom: 1px solid #E8E8E8;


      ${(props) => props.border && css`
        border: 1px solid #E8E8E8;
      `}
      display: flex;
      align-items: center;
      margin-top: 4px;
      margin-bottom: 4px;


      > .search {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      
      input {
        background-color: rgba(0, 0, 0, 0);
        padding: 2px 4px;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        //padding: 9px 11px 9px 11px;
        color: #555555;
        display: block;
        width: 100%;
        height: 36px;
        box-sizing: border-box;
        border: none;
        
        ::placeholder {
          color: #BBBBBB;
          font-size: 14px;
          font-weight: 500;
        }
      }

      textarea {
        padding: 2px 4px;
        width: 100%;
        border: none;
        outline: none;
        resize: none;
        font-size: 16px;
        ::placeholder {
          color: #BBBBBB;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    > .upload-btn {
      cursor: pointer;
      margin-top: 8px;
      border-radius: 19px;
      width: 114px;
      height: 37px;
      line-height: 37px;
      border: 1px solid #ededed;
      text-align: center;
      color: #EEEEEE;
      background-color: #444444;
      font-weight: 500;
    }
  }
`;
