import {Route, Routes, useLocation} from "react-router-dom";
import NotFoundPage from "./page/error/NotFoundPage";
import Layout from "./layout/Layout";
import {createTheme} from "styled-breakpoints";
import {ThemeProvider} from "styled-components";
import {useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {get} from "lodash";
import VehicleListPage from "./page/vehicle/VehicleListPage";
import VehiclePage from "./page/vehicle/VehiclePage";
import {GET_ME} from "./query/userQuery";
import HostLanding2Page from "./page/event/HostLanding2Page";
import {CalendarPage} from "./page/my/CalendarPage";
import {LoginPage} from "./page/login/LoginPage";
import LoadingIndicator from "./layout/LoadingIndicator";
import {loadingAction} from "./redux/loadingReducer";
import {userAction} from "./redux/userReducer";
import VehicleGuestWritePage from "./page/vehicle/VehicleGuestWritePage";
import NotificationPage from "./page/my/NotificationPage";
import {authAction} from "./redux/authReducer";
import {MyDooricarPage} from "./page/my/MyDooricarPage";
import {CardSuccessPage} from "./page/card/CardSuccess";
import {PaymentPage} from "./page/my/PaymentPage";
import {PaymentSuccessPage} from "./page/card/PaymentSuccess";
import {GuidePage} from "./page/guide/GuidePage";
import {UserPage} from "./page/user/UserPage";
import {Alert, Snackbar} from "@mui/material";
import {ProfileEditPage} from "./page/my/ProfileEditPage";
import DailyUpdatePage from "./page/my/DailyUpdatePage";
import {EventDetailPage} from "./page/event/EventDetailPage";
import {Login2Page} from "./page/login/Login2Page";
import {LoginCallbackPage} from "./page/login/LoginCallbackPage";
import {flatEntity} from "./toolbox/query";
import {WalletPage} from "./page/my/WalletPage";
import {EmailIgnorePage} from "./page/my/EmailIgnorePage";
import HostSimpleWritePage from "./page/event/HostSimpleWritePage";
import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import VehicleWrite2Page from "./page/vehicle/VehicleWrite2Page";
import {KakaoFloat} from "./layout/KakaoFloat";
import CalcPage from "./page/admin/CalcPage";
import {MatchingReplyPage} from "./page/vehicle/MatchingReplyPage";
import ManualMatchPage from "./page/admin/ManualMatchPage";
import {PaymentManagePage} from "./page/admin/PaymentManagePage";
import {HostChatReplyPage} from "./page/vehicle/HostChatReplyPage";
import {PaymentManagePage2} from "./page/admin/PaymentManagePage2";
import MatchingWritePage from "./page/vehicle/MatchingWrite/MatchingWritePage";
import MatchingViewPage from "./page/vehicle/MatchingView/MatchingViewPage";
import {ChatListPage} from "./page/my/ChatList";
import {ChatDetailPage} from "./page/my/ChatDetailPage";
import {ChatCreatePage} from "./page/my/ChatCreatePage";
import {ChatAdminPage} from "./page/my/ChatAdminPage";
import {LeavePage} from "./page/my/LeavePage";
import VehicleMainPage from "./page/vehicle/VehicleMainPage";
import GuestListPage from "./page/vehicle/GuestListPage";
import axios from "axios";
import { SERVER_ADDRESS } from ".";

const theme = createTheme({
    pc: '992px',
});

const VEHICLE_IGNORE = [
    "/card_success",
    "/card_fail",
    "/my/calendar",
    "/login",
]
const App = () => {
    const { loading } = useSelector(({loading}) => loading);
    const { toast } = useSelector(({toast}) => toast);
    const jwt = useSelector(({auth}) => auth.token);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);
    const dispatch = useDispatch();
    const location = useLocation();

    const [toastOpen, setToastOpen] = useState(false);

    const {data: user, refetch: refetchUser} = useQuery(GET_ME, {
        fetchPolicy: "cache-first",
        skip: !jwt,
        variables: {id: tokenInfo?.id}
    });

    useEffect(() => {
        if (jwt) {
            refetchUser().then(() => {});
        }
    }, [jwt]);

    useEffect(() => {
        if (toast) {
            setToastOpen(true);
        } else {
            setToastOpen(false);
        }
    }, [toast])

    useEffect(() => {
        if (tokenInfo) {
            const period = 86400 * 7;
            const now = Date.now() / 1000;
            if (tokenInfo.exp < now + period) {
                dispatch(authAction.logout());
                dispatch(userAction.logout());
            }
        }
    }, [tokenInfo])

    useEffect(() => {
        if (tokenInfo) {
            axios.post(SERVER_ADDRESS + '/api/daily-updates/touch', {}).then((res) => {});
        }
    }, [tokenInfo, location]);

    // useEffect(() => {
    //     ChannelService.loadScript();
    //     if (userInfo) {
    //         ChannelService.boot({
    //             pluginKey: "1a3e5474-9aff-45ee-9a9b-6ac0dcfb24a8",
    //             memberId: userInfo.id, // fill user's member id
    //             profile: { // fill user's profile
    //                 name: userInfo.realname,
    //                 email: userInfo.email,
    //                 mobileNumber: userInfo.phone,
    //             }
    //         });
    //     } else {
    //         ChannelService.boot({
    //             pluginKey: "1a3e5474-9aff-45ee-9a9b-6ac0dcfb24a8",
    //         });
    //     }
    // }, [userInfo]);
    //
    // useEffect(() => {
    //     ChannelService.loadScript();
    //     ChannelService.setPage(`${location.pathname}${location.search}${location.hash}`);
    // }, [location])

    useEffect(() => {
        if (user) {
            const payload = get(user, "usersPermissionsUser.data", null);
            dispatch(userAction.update(flatEntity(payload)));
        }
    }, [user]);

    useEffect(() => {
        dispatch(loadingAction.loading(false));
        window.scrollTo(0, 0);
    }, [location]);

    return (
      <ThemeProvider theme={theme}>
          <Layout>
              <Routes>
                  <Route path="/" element={<VehicleMainPage/>}/>
                  <Route path="/vehicle" element={<VehicleListPage/>}/>
                  <Route path="/vehicle/:id" element={<VehiclePage/>}/>
                  <Route path="/vehicle/:id/request" element={<MatchingWritePage />}/>
                  <Route path="/matching/:id" element={<MatchingViewPage />}/>
                  <Route path="/vehicle/write" element={<VehicleWrite2Page/>}/>
                  <Route path="/vehicle_modify" element={<VehicleWrite2Page/>}/>
                  <Route path="/host" element={<HostLanding2Page/>}/>
                  <Route path="/host_result" element={<HostSimpleWritePage/>}/>
                  <Route path="/test" element={<CalendarPage/>}/>
                  <Route path="/event" element={<EventDetailPage/>} />
                  <Route path="/admin/calc" element={<CalcPage/>} />
                  <Route path="/admin/chat" element={<ChatAdminPage/>} />
                  <Route path="/admin/manual_match" element={<ManualMatchPage/>} />
                  <Route path="/admin/payment" element={<PaymentManagePage2/>} />
                  <Route path="/admin/payment2" element={<PaymentManagePage/>} />

                  <Route path="/user" element={<GuestListPage/>}/>
                  <Route path="/user/:id" element={<UserPage/>}/>

                  <Route path="/my/info" element={<MyDooricarPage/>}/>
                  <Route path="/my/calendar" element={<CalendarPage/>}/>
                  <Route path="/my/payment" element={<PaymentPage/>}/>
                  <Route path="/my/wallet" element={<WalletPage/>}/>
                  <Route path="/my/matching" element={<NotificationPage/>}/>
                  <Route path="/my/notification" element={<NotificationPage/>}/>
                  <Route path="/my/daily_update" element={<DailyUpdatePage/>}/>
                  <Route path="/my/profile" element={<ProfileEditPage/>}/>
                  <Route path="/my/chat" element={<ChatListPage/>}/>
                  <Route path="/my/chat/:id" element={<ChatDetailPage/>}/>
                  <Route path="/my/chat_start" element={<ChatCreatePage/>}/>
                  <Route path="/my/leave" element={<LeavePage/>}/>
                  <Route path="/card_success" element={<CardSuccessPage/>}/>
                  <Route path="/payment_success" element={<PaymentSuccessPage/>}/>

                  <Route path="/guide" element={<GuidePage/>}/>

                  <Route path="/login" element={<Login2Page/>}/>
                  <Route path="/login2" element={<LoginPage/>}/>
                  <Route path="/login_callback" element={<LoginCallbackPage/>}/>
                  <Route path="/matching_reply" element={<MatchingReplyPage/>}/>
                  <Route path="/chat_create_reply" element={<HostChatReplyPage/>}/>
                  <Route path="/emailignore" element={<EmailIgnorePage/>}/>
                  <Route path="/*" element={<NotFoundPage/>}/>
              </Routes>
              {/*{!location.pathname.startsWith('/my') && <KakaoFloat />}*/}
              <KakaoFloat path={location.pathname} />
          </Layout>
          <LoadingIndicator isVisible={loading} />
          <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center',}} autoHideDuration={3000} open={toastOpen} onClose={() => setToastOpen(() => false)} >
              <Alert severity={toast.severity ?? 'success'}>{toast.message}</Alert>
          </Snackbar>
      </ThemeProvider>
  );
}

export default App;
