import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import styled, {css} from "styled-components";
import {parseJwt, useForm} from "../../toolbox";
import {authAction} from "../../redux/authReducer";
import {userAction} from "../../redux/userReducer";
import {loadingAction} from "../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import { isEmpty } from "lodash";
import { FormComponent } from "../vehicle/VehicleAdd2/FormComponent";

const PageWrap = styled.div`
  padding: 20px;
  text-align: center;
`

export function LeavePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const {form, onChange} = useForm({
      reason: '',
    });

    useEffect(() => {
      if (isEmpty(tokenInfo)) {
          navigate('/');
      }
    }, [tokenInfo]);

    const handleLeave = useCallback(async () => {
      if (!form.reason) {
          alert('탈퇴 사유를 입력해주세요');
          return;
      }

      if (window.confirm('정말 탈퇴하시겠습니까?')) {
          dispatch(loadingAction.loading(true));
          const res = await axios.post(SERVER_ADDRESS + '/api/daily-updates/leave', {
              reason: form.reason,
          });
          if (res.data.code === 'SUCCESS') {
            dispatch(loadingAction.loading(false));
            alert('탈퇴가 완료되었습니다.');
            dispatch(authAction.logout());
            dispatch(userAction.logout());
            navigate('/');
          } else {
            alert(res.data.message);
          }
      }
  }, [form.reason]);

    return (<PageWrap>
      <h2>서비스 탈퇴</h2>
      <div style={{width: '100%', maxWidth: '440px', marginTop: '30px', marginBottom: '30px'}}>
        <FormComponent title="탈퇴 사유" id="reason" form={form} onChange={onChange} />
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <LogoutButton onClick={handleLeave}>탈퇴하기</LogoutButton>
      </div>
    </PageWrap>);
};


const LogoutButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px; 

  &:hover {
    background-color: #c82333;
  }
`;
