import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState, useMemo} from "react";
import {asyncSendTelegram, calcDistance, commaNum} from "../../toolbox";
import {cloneDeep, filter, find, get, groupBy, isEmpty, orderBy, reverse, sortBy} from "lodash";
import {
    calcTimeDiff,
    distanceFormat,
    getLoginLink,
    isPhoneFormat,
    isUrlFormat,
    toPhoneFormat
} from "../../toolbox/format";
import {useMutation, useQuery} from "@apollo/client";
import {
    UPDATE_NOTIFICATION
} from "../../query/userQuery";
import {Mannerbox} from "../vehicle/component/Mannerbox";
import {loadingAction} from "../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import ReactMarkdown from "react-markdown";
import {authAction} from "../../redux/authReducer";
import moment from "moment-timezone";
import {DailyUpdateList} from "./DailyUpdatePage";
import queryString from "query-string";
import { useCallback } from "react";

export const ChatListPage = () => {
    const [chatRooms, setChatRooms] = useState(null);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    useEffect(() => {
        if (!isEmpty(tokenInfo)) {
            axios.post(SERVER_ADDRESS + '/api/chat-rooms/findMyRooms', {}).then((res) => {
                setChatRooms(res.data);
            });
        }
    }, [tokenInfo]);
    return <ChatList chatRooms={chatRooms} />
}

export const ChatList = ({chatRooms, max, isAdmin}) => {
    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, [tokenInfo]);

    const getOtherUser = useCallback((room) => {
        if (!chatRooms) {
            return null;
        }
        const otherUsers = filter(room.users, u => String(u.id) !== String(tokenInfo.id));
        if (otherUsers.length) {
            return otherUsers[0];
        }
        return null;
    }, [chatRooms, tokenInfo.id]);

    const getOtherUsers = useCallback((room) => {
        if (!chatRooms) {
            return null;
        }
        const otherUsers = filter(room.users, u => String(u.id) !== String(tokenInfo.id));
        return otherUsers;
    }, [chatRooms, tokenInfo.id]);

    const chatRoomTitle = useMemo(() => {
        return (room) => {
            if (room?.contract?.users_permissions_user?.id) {
                if (String(tokenInfo.id) !== String(room.contract.users_permissions_user.id)) {
                    return `${room.contract.vehicle.brand} ${room.contract.vehicle.model} 호스트 (${room.contract.vehicle.numberPlate})`;
                }
            }
            const otherUsers = filter(room.users, u => String(u.id) !== String(tokenInfo.id));
            if (otherUsers.length) {
                if (room.contract) {
                    return `${otherUsers[0].nickname} 게스트 (${room.contract.vehicle.brand} ${room.contract.vehicle.model})`;
                }
                return otherUsers.map(u => u.nickname).join(', ');
            }
            return '두리카';
        };
    }, [tokenInfo.id]);

    const chatRoomImage = useMemo(() => {
        return (room) => {
            const otherUser = getOtherUser(room);
            let img;
            if (otherUser) {
                img = otherUser?.profile_image?.url;
            } else {
                img = '/layout/logo.png';
            }
            return img ?? '/layout/char_doori.svg';
        };
    }, [getOtherUser]);

    const lastMessage = useMemo(() => {
        return (room) => {
            if (!room.lastMessage) {
                return '표시할 메세지가 없습니다.';
            }
            if (room.lastMessage.type === 'text') {
                return room.lastMessage.content;
            }
            if (room.lastMessage.type === 'image') {
                return "사진을 보냈습니다.";
            }
        };
    }, []);

    const unread = useMemo(() => {
        return (room) => {
            const lastMessage = room?.lastMessage?.sentAt;
            if (lastMessage) {
                const read = room.readAt?.[tokenInfo.id];
                if (read && lastMessage <= read) {
                    return false;
                }
                return true;
            }
            return false;
        };
    }, [tokenInfo.id]);

    const sortedChatRooms = useMemo(() => {
        let cr = orderBy(chatRooms, ['lastMessage.sentAt'], ['desc']);
        if (isAdmin) {
            cr = cr.filter(room => {
                const content = room?.lastMessage?.content;
                if (!content) {
                    return true;
                }
                return !(
                    content.includes("제 차량 확인 후") || 
                    content.startsWith("매칭제안 도착알림")
                );
            });
        }
        if (max) {
            return cr.splice(0, max);
        }
        return cr;
    }, [chatRooms, max, isAdmin]);

    return <div>
        {chatRooms && sortedChatRooms.map((room) =>
            <Link to={"/my/chat/" + room.id} key={room.id}>
                <ChatRoom>
                    <div className="container">
                        <img className="profile_image" src={chatRoomImage(room)} />
                        <div className="content">
                            <div className="title">{chatRoomTitle(room)}</div>
                            <div className={unread(room)? 'unread-message': 'read-message'}>{lastMessage(room)}</div>
                        </div>
                        {unread(room) && <div className="unread" />}
                    </div>
                </ChatRoom>
            </Link>)}

        {chatRooms && chatRooms.length === 0 && <div style={{padding: '20px'}}>
            새로운 알림이 없습니다.
        </div>}
    </div>
}

const ChatRoom = styled.div`
  padding: 8px 10px;
  box-sizing: border-box;
  
  .container {
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #EEEEEE;
    }
  }


  .profile_image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  
  .content {
    box-sizing: border-box;
    width: 100%;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
  }
  
  .title {
    box-sizing: border-box;
    font-weight: 600;
  }

  .unread-message {
    width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .read-message {
    width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #808080;
  }
  
  .unread {
    margin-left: 10px;
    min-width: 10px;
    width: 10px;
    height: 10px;
    background-color: #007bff;
    border-radius: 50%;
    display: inline-block;
  }
`;