import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {cloneDeep, get, isEmpty} from "lodash";
import {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import {commaNum, useForm} from "../../../toolbox";
import {useMutation, useQuery} from "@apollo/client";
import {UPDATE_USER} from "../../../query/userQuery";
import {MB32} from "../../../toolbox/UtilComponent";
import {loadingAction} from "../../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../../index";
import {convertDooricar, getLoginLink, getUseTypeTxt} from "../../../toolbox/format";
import {toastAction} from "../../../redux/toastReducer";
import queryString from "query-string";
import {GET_DOORI_VEHICLE} from "../../../query/vehicleQuery";
import LoadingIndicator from "../../../layout/LoadingIndicator";
import {FormComponent, RadioComponent, RadioComponentSimple} from "../VehicleAdd2/FormComponent";
import {getDescriptionSimple, VehicleUseInfoSection3} from "../component/VehicleUseInfoSection";
import {flatEntity} from "../../../toolbox/query";
import UserInfo from "./UserInfo";
import BottomNavigator from "../VehicleAdd2/BottomNavigator";
import MatchInfo from "./MatchInfo";
import ToHost from "./ToHost";
import moment from "moment-timezone";
const MatchingWritePage = () => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const guestId = parsed?.guestId;
    const vehicleId = params.id;
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const user = useSelector(({user}) => user.user);

    const {data, refetch, loading} = useQuery(GET_DOORI_VEHICLE, {variables: {id: vehicleId}});
    const [vehicle, setVehicle] = useState(null);

    const [page, setPage] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    useEffect(() => {
        if (data) {
            const payload = flatEntity(get(data, "vehicle.data", null));
            if (payload) {
                let v = convertDooricar(payload);
                if (v) {
                    setVehicle(v);
                } else {
                    alert('이미 매칭이 되었거나 비활성화된 차량입니다.');
                    navigate(-1);
                }
            } else {
                alert('이미 매칭이 되었거나 비활성화된 차량입니다.');
                navigate(-1);
            }
        }
    }, [data]);

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, [tokenInfo]);

    const [finished, setFinished] = useState(false);
    const [updateUser] = useMutation(UPDATE_USER);

    const {form, onChange} = useForm({
        nickname: '',
        // phone: '',
        // address: '',
        // detail_address: '',
        '생년월일': '',
        '성별': '',
        // '운전경력': '',
        '자기소개': '',
        '실내흡연여부': '',
        '반려동물 탑승 방법': '',
        '시작 희망일': moment.tz('Asia/Seoul').add(3, 'day').format('YYYY-MM-DD'),
        // '예상 이용기간': '',
        '예상 운행거리': '',
        '추가운전자여부': '없음',
        '추가운전자': '',
        '매칭유형': '',
        '호스트에게 한마디': '',
        // '6개월 약정 여부': '적용',
    });

    useEffect(() => {
        if (user && user.matching_profile) {
            const keys = ["성별", "생년월일", "실내흡연여부", "예상 운행거리", "자기소개", "반려동물 탑승 방법"];
            for (let key in user.matching_profile) {
                if (!keys.includes(key)) {
                    continue;
                }
                if (form[key] !== undefined) {
                    onChange({id: key, value: user.matching_profile[key]});
                }
            }
        }
        if (user && user.nickname) {
            onChange({id: 'nickname', value: user.nickname});
        }
    }, [user]);

    const save = async () => {
        if (finished) {
            return;
        }

        const dto = cloneDeep(form);
        if (dto.추가운전자여부 === '없음') {
            dto.추가운전자 = '없음';
        }

        for (let key in dto) {
            if (key === '자기소개') {
                continue;
            }
            if (key === '호스트에게 한마디') {
                continue;
            }
            if (key === '추가운전자') {
                continue;
            }
            if (key === '매칭유형') {
                if (!vehicle.useTypes.map(u => u.id).includes(dto[key])) {
                    dispatch(toastAction.toast({severity: 'error', 'message': '매칭 유형을 체크해주세요'}));
                    return;
                }
            }
            if (!dto[key]) {
                dispatch(toastAction.toast({severity: 'error', 'message': '모든 내용을 채워주세요'}));
                return;
            }
        }

        const userDto = {
            nickname: dto.nickname,
            // phone: dto.phone,
            // address: dto.address + '\t' + dto.detail_address,
        }

        // const requestDto = {
        //     user: tokenInfo.id,
        //     contract: vehicle.contract,
        //     title: dto.title,
        //     availableDate: dto.availableDate,
        // }

        dispatch(loadingAction.loading(true));
        const keys = ["성별", "생년월일", "실내흡연여부", "예상 운행거리", "자기소개", "반려동물 탑승 방법"];
        const _matching_profile = Object.keys(form).reduce((result, key) => {
            if (keys.includes(key)) {
                result[key] = form[key];
            }
            return result;
        }, {});

        if (user?.matching_profile?.["원하는 차량"]) {
            _matching_profile["원하는 차량"] = user.matching_profile["원하는 차량"];
        }

        const payload = {
            id: tokenInfo.id,
            data: {
                matching_profile: _matching_profile,
            }
        }

        if (user.nickname !== userDto.nickname) {
            payload.data.nickname = userDto.nickname;
        }

        window.fbq('track', 'AddToWishlist');
        updateUser({
            variables: payload,
        }).then(() => {});

        if (guestId) {
            const res = await axios.post(SERVER_ADDRESS + '/api/vehicles/matchingRequest', {
                title: '찜한 렌터카 차량에 요청',
                payload: {
                    신청한사람: `${userDto.nickname} (${tokenInfo?.id}, ${user.phone})`,
                    신청받은사람: `${vehicle?.user?.nickname} (${vehicle?.user?.id}, ${vehicle?.user?.phone}, https://www.dooricar.com/user/${guestId})`,
                    차량: `${vehicle.numberPlate} (${vehicle.id})`,
                    차량페이지: `https://www.dooricar.com/vehicle/${vehicleId}?mode=solo&guestId=${guestId}`,
                    ...dto,
                },
            });
        } else {
            const res = await axios.post(SERVER_ADDRESS + '/api/vehicles/matchingRequest', {
                title: '호스트 차량에 요청',
                contractId: vehicle.contract,
                payload: {
                    게스트: `${userDto.nickname} (${tokenInfo?.id}, ${user.phone})`,
                    호스트: `${vehicle?.user?.nickname} (${vehicle?.user?.id}, ${vehicle?.user?.phone})`,
                    차량: `${vehicle.numberPlate} (${vehicle.id})`,
                    차량페이지: `https://www.dooricar.com/vehicle/${vehicleId}?mode=doori`,
                    ...dto,
                },
            });

            // const payload = JSON.stringify({
            //     게스트: `${userDto.realname} (${tokenInfo?.id}, ${user.phone})`,
            //     차량: `${vehicle.numberPlate} (${vehicle.id})`,
            //     신청서: dto,
            // }, null, 2);
            // await asyncSendTelegram("타던차 매칭 신청 (호스트 차량)\n" + payload);
            // const res = await axios.post(SERVER_ADDRESS + '/notifications/matchingSend', {contractId: vehicle.contract}, {});
        }
        setFinished(true);
        dispatch(loadingAction.loading(false));
        alert("상대방이 매칭을 수락하면 담당 매니저가 개별적으로 연락을 드려서 세부절차를 안내드립니다.")
        navigate(-1);
    }

    return <PageWrap>
        {page === 0 && <UserInfo form={form} onChange={onChange} />}
        {page === 1 && <MatchInfo form={form} onChange={onChange} vehicle={vehicle} />}
        {page === 2 && <ToHost form={form} onChange={onChange} />}
        <BottomNavigator page={page}
                         onBefore={() => setPage((p) => p-1)}
                         onNext={() => {
                             // if (page === 2) {
                             //     if (!form.useTypes) {
                             //         dispatch(toastAction.toast({severity: 'error', 'message': '매칭 유형을 1개 이상 선택해주세요.'}));
                             //         return;
                             //     }
                             // }
                             setPage((p) => p+1);
                         }}
                         lastPage={2}
                         onSubmit={save} />
        {/*<SubmitBtn finished={finished} onClick={save}>{finished? '신청완료': '신청하기'}</SubmitBtn>*/}
        <LoadingIndicator isVisible={loading || !user} />
    </PageWrap>
}

export default MatchingWritePage;

const PageWrap = styled.div`
  box-shadow: 0px -6px 20px -9px rgba(0, 0, 0, 0.18);
`;

const Wrap = styled.div`
  padding: 32px 20px 32px;
  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  
  h2 {
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  
  p {
    color: var(--gray-scale-gray-700, #939393);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 4px 0;
  }
  
  .underline {
    color: var(--mint, #33CCC1);
    text-decoration-line: underline;
    cursor: pointer;
  }
  
  .big {
    font-size: 14px;
  }
  
  .bold {
    color: #212121;
    font-weight: 600;
  }

  > .forms {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  
  > .forms2 {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`

const SubmitBtn = styled.div`
  box-sizing: border-box;
  margin-left: 40px;
  margin-right: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;

  ${(props) => props.finished && css`
    background-color: gray;
    cursor: not-allowed;
  `}
`
const Card1 = styled.div`
  padding: 30px 20px 20px;
  > h3 {
    font-family: 'Pretendard', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #00D0C1;
  }

  > div {
    > h1 {
      font-family: 'Pretendard', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
      color: #00D0C1;
      text-align: center;
    }
    > h2 {
      font-family: 'Pretendard', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
      color: black;
      text-align: center;
      margin-bottom: 42px;
    }

    > p {
      font-family: 'Pretendard', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #646464;
      text-align: center;
    }
  }
`

const TextBox = styled.div`
  padding: 16px 12px;
  border-radius: 8px;
  background: var(--gray-scale-gray-200, #F5F5F5);

  color: var(--gray-scale-gray-700, #939393);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */

  ${(props) => props.onClick && css`
    cursor: pointer;
  `}
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
  }
  .thin {
    font-weight: 400;
  }
  .link {
    font-weight: 600;
    text-decoration-line: underline;
    cursor: pointer;
  }
`
