import styled, {css} from "styled-components";
import {
    FormComponent,
    PhotoComponent,
    RadioComponent,
    SafetyDialog,
    SelectComponent,
    UseTypeComponent
} from "./FormComponent";
import ReactSlider from "react-slider";
import {commaNum} from "../../../toolbox";
import React, {useEffect, useState} from "react";
import BottomDrawer, {DrawerWrap} from "./BottomDrawer";
import {PositiveBtn} from "./BottomNavigator";
import axios from "axios";
import {SERVER_ADDRESS} from "../../../index";
import {isNumberPlateFormat} from "../../../toolbox/format";
import {loadingAction} from "../../../redux/loadingReducer";
import {useDispatch} from "react-redux";
import {calcOil, calcTax, fillUsedCarInfo} from "../../../toolbox/calculate";
import {Dialog} from "@mui/material";
import moment from "moment-timezone";
import {SearchBtn} from "./VehicleInfo";
import {cloneDeep, filter, find, sortBy} from "lodash";
import * as PropTypes from "prop-types";
import {UseTypeDrawer} from "./MatchingInfo";
import {getDescriptionSimple} from "../component/VehicleUseInfoSection";

const PriceSet = ({form, onChange}) => {
    const [open, setOpen] = useState(false);
    const [insuranceOpen, setInsuranceOpen] = useState(false);
    const [useTypeOpen, setUseTypeOpen] = useState(false);
    const [useType, setUseType] = useState(null);
    const [detailOpen, setDetailOpen] = useState(false);
    const dispatch = useDispatch();

    const checked = (v) => {
        if (form.useTypes) {
            const vs = form.useTypes.split(',');
            return vs.includes(v);
        }
        return false;
    }

    const onClickPaste = () => {
        onChange({id: 'useTypes', value: 'DAY_SELECT,PRIORITY_HOST,PRIORITY_GUEST'});
        for (let type of ['DAY_SELECT', 'PRIORITY_HOST', 'PRIORITY_GUEST']) {
            onChange({id: `price_${type}`, value: form[`예상월분담액_${type}`]});
        }

        if (form["예상거리당주유비"]) {
            onChange({id: "doorivePriceKm", value: form["예상거리당주유비"]});
        }
    }

    const onUseTypeChange = ({id, value}) => {
        const beforeIds = form[id].split(',');
        const afterIds = value.split(',');
        let uniqueInAfter = afterIds.filter(item => !beforeIds.includes(item));
        if (uniqueInAfter.length) {
            if (uniqueInAfter.includes("DAY_SELECT") || uniqueInAfter.includes("PRIORITY_HOST")) {
                // setUseTypeOpen(true);
            }
        }
        onChange({id, value});
    }

    const toggle = (value) => {
        const id = 'useTypes';
        let options = [];
        if (form[id]) {
            options = form[id].split(',');
        }
        if (options.includes(value)) {
            options = filter(options, (o) => o !== value);
        } else {
            options.push(value);
        }
        options = sortBy(options, (v) => {
            if (v === 'DAY_SELECT') {
                return 0;
            } else if (v === 'PRIORITY_HOST') {
                return 1;
            } else if (v === 'PRIORITY_GUEST') {
                return 2;
            }
        });
        onUseTypeChange({id: 'useTypes', value: options.join(',')})
    }

    useEffect(() => {
        if (form.하이픈중고차가격) {
            let result = form.하이픈중고차가격;
            if (typeof(result) === 'string') {
                result = Number(form.하이픈중고차가격.replace(/[^0-9]/g, ""));
            }
            onChange({id: '차량시세', value: Math.round(result / 10000 / 100) * 100});
        }
    }, [form.하이픈중고차가격]);

    useEffect(() => {
        if (form.차량시세) {
            const price = form.차량시세 * 10000;
            const result = fillUsedCarInfo(price, form.year);
            const tax = calcTax(form.year, form.displacement) ?? 0;
            const oil = calcOil(price);
            const 기타비용 = result.월간정비분담액 + result.월간보험분담액 + (tax / 12 / 2);

            const prices = {
                DAY_SELECT: Math.round((result.월간감가분담액 + 기타비용) / 1000) * 1000,
                PRIORITY_HOST: Math.round((result.월간감가분담액 + 기타비용) * 0.5 / 1000) * 1000,
                PRIORITY_GUEST:Math.round((result.월간감가분담액 + 기타비용) * 1.5 / 1000) * 1000,
            };

            for (let t in prices) {
                onChange({id: `예상월분담액_${t}`, value: prices[t]});
                if (!form[`price_${t}`] || form[`예상월분담액_${t}`] == form[`price_${t}`]) {
                    onChange({id: `price_${t}`, value: prices[t]});
                }
            }
            onChange({id: '예상거리당주유비', value: Math.round(oil)});


            if (form.later === 'LATER') {
                for (let t in prices) {
                    onChange({id: `price_${t}`, value: prices[t]});
                }
                if (form["예상거리당주유비"]) {
                    onChange({id: "doorivePriceKm", value: form["예상거리당주유비"]});
                }
            }
        }
    }, [form.차량시세]);

    useEffect(() => {
        if (form.later === 'LATER') {
            onClickPaste();
        }
    }, [form.later]);

    useEffect(() => {
        if (form.doorivePriceTax) {
            const yearAmount = Number(form.doorivePriceTax) / 2;
            onChange({id: '예상세금', value: Math.round(yearAmount / 12)});
        } else {
            onChange({id: '예상세금', value: ''});
        }
    }, [form.doorivePriceTax]);

    return <>
        {/*<Wrap>*/}
        {/*    <div className="forms">*/}
        {/*        <h1>내 차 수익 설정하기</h1>*/}
                {/*<div>*/}
                {/*    <h2>추천 수익</h2>*/}
                {/*    <TextBox>*/}
                {/*        {form.하이픈차종?*/}
                {/*            <p>차종: {form.하이픈차종} [{form.하이픈차량번호}]<br/>*/}
                {/*            내 차 현재 시세(추정): {commaNum(form.하이픈중고차가격)}원</p>*/}
                {/*            : <p>차량 조회에 실패하여 시세 정보를 불러오지 못했습니다.<br/>*/}
                {/*                아래 슬라이더를 움직여 수동으로 '내 차 현재 시세'를 설정해주세요.*/}
                {/*            </p>}*/}
                {/*    </TextBox>*/}
                {/*    <h4>‘내 차 현재 시세’는 추정 값으로, 실제와 많이 다를 수 있습니다. bar를 좌우로 이동시켜 차량 시세에 따른 추천 수익을 아래에서 확인해보세요!<br/>*/}
                {/*        /!*{getUseType()}*!/*/}
                {/*    </h4>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <StyledSlider*/}
                {/*        className="horizontal-slider"*/}
                {/*        thumbClassName="--circle"*/}
                {/*        trackClassName="--track"*/}
                {/*        defaultValue={2500}*/}
                {/*        step={100}*/}
                {/*        min={300}*/}
                {/*        max={15000}*/}
                {/*        value={form.차량시세}*/}
                {/*        onChange={(v) => onChange({id: '차량시세', value: v})}*/}
                {/*        renderThumb={(props, state) => <div {...props}>{commaNum(state.valueNow)}만원</div>}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>*/}
                {/*    <h2 style={{fontSize: '18px'}}>추천 월이용료</h2>*/}
                {/*    <Flexbox>*/}
                {/*        <h2 style={{minWidth: '90px', color: '#212121'}}>요일지정형</h2>*/}
                {/*        <div style={{flexGrow: '1'}}>*/}
                {/*            <FormComponent id="예상월분담액_DAY_SELECT" disabled unit="원 / 월" form={form} onChange={onChange} />*/}
                {/*        </div>*/}
                {/*    </Flexbox>*/}
                {/*    <Flexbox>*/}
                {/*        <h2 style={{minWidth: '90px', color: '#212121'}}>호스트 우선형</h2>*/}
                {/*        <div style={{flexGrow: '1'}}>*/}
                {/*            <FormComponent id="예상월분담액_PRIORITY_HOST" disabled unit="원 / 월" form={form} onChange={onChange} />*/}
                {/*        </div>*/}
                {/*    </Flexbox>*/}
                {/*    <Flexbox>*/}
                {/*        <h2 style={{minWidth: '90px', color: '#212121'}}>게스트 우선형</h2>*/}
                {/*        <div style={{flexGrow: '1'}}>*/}
                {/*            <FormComponent id="예상월분담액_PRIORITY_GUEST" disabled unit="원 / 월" form={form} onChange={onChange} />*/}
                {/*        </div>*/}
                {/*    </Flexbox>*/}
                {/*    <h3>추천 월이용료는 차량의 시세와 연식에 따른 감가상각, 보험료, 자동차세, 정비비를 고려하여 산출됩니다.</h3>*/}
                {/*</div>*/}


                {/*<div>*/}
                {/*    <Flexbox>*/}
                {/*        <h2 style={{whiteSpace: 'nowrap'}}>보험·세금분담수익</h2>*/}
                {/*        <div style={{flexGrow: '1'}}>*/}
                {/*            <FormComponent id="예상보험세금" placeholder={getPlaceholder()} disabled unit="원 / 월" form={form} onChange={onChange} />*/}
                {/*        </div>*/}
                {/*    </Flexbox>*/}
                {/*    <h4>연간 보험료와 자동차세 합산액의 절반을 게스트가 납부합니다. (12개월로 나누어서 계산)</h4>*/}
                {/*</div>*/}
                {/*<div style={{marginTop: '8px', display: 'flex', flexDirection: 'column', gap: '12px'}}>*/}
                {/*    <h2 style={{fontSize: '18px'}}>추천 거리당수익</h2>*/}
                {/*    <Flexbox>*/}
                {/*        <h2 style={{minWidth: '90px', color: '#212121'}}>거리당 관리비</h2>*/}
                {/*        <div style={{flexGrow: '1'}}>*/}
                {/*            <FormComponent id="예상거리당관리비" disabled unit="원 / km" placeholder="연식 정보 입력이 필요합니다" form={form} onChange={onChange} />*/}
                {/*        </div>*/}
                {/*    </Flexbox>*/}
                {/*    <Flexbox>*/}
                {/*        <h2 style={{minWidth: '90px', color: '#212121'}}>거리당 주유비</h2>*/}
                {/*        <div style={{flexGrow: '1'}}>*/}
                {/*            <FormComponent id="예상거리당주유비" disabled unit="원 / km" placeholder="연식 정보 입력이 필요합니다" form={form} onChange={onChange} />*/}
                {/*        </div>*/}
                {/*    </Flexbox>*/}
                {/*</div>*/}


                {/*<div style={{marginTop: '8px', display: 'flex', flexDirection: 'column', gap: '12px'}}>*/}
                {/*    <h2 style={{fontSize: '18px'}}>추천 기타수익</h2>*/}
                {/*    <Flexbox>*/}
                {/*        <h2 style={{minWidth: '115px', whiteSpace: 'nowrap'}}>자동차세 분담수익</h2>*/}
                {/*        <div style={{flexGrow: '1', margin: '6px 0'}} onClick={() => {*/}
                {/*            // setInsuranceOpen(true)*/}
                {/*        }}>*/}
                {/*            <FormComponent id="예상세금" disabled unit="원 / 월" form={form} onChange={() => {}} />*/}
                {/*        </div>*/}
                {/*    </Flexbox>*/}
                {/*    <Flexbox>*/}
                {/*        <h2 style={{minWidth: '115px', color: '#212121'}}>보험료 분담수익</h2>*/}
                {/*        <div style={{flexGrow: '1'}}>*/}
                {/*            <FormComponent id="예상보험료" disabled unit="원 / 월" form={form} onChange={onChange} />*/}
                {/*        </div>*/}
                {/*    </Flexbox>*/}
                {/*</div>*/}
        {/*    </div>*/}
        {/*</Wrap>*/}
        {/*<Separator/>*/}
        <Wrap>
            <h1>내 차 수익 설정하기</h1>
            <div className="forms">
                {/*<div>*/}
                {/*    <h2>수익 설정</h2>*/}
                {/*    <h3>위 추천수익을 참고하여 실제로 매칭 시 게스트로부터 받을 수익을 자유롭게 입력해주세요. (추후 변경 가능하며 매칭 수락 시 최종 확정됩니다.)</h3>*/}
                {/*</div>*/}


                <div style={{marginTop: '16px'}}>
                    <TextBox>
                        {form.하이픈차종?
                            <p>차종: {form.하이픈차종} [{form.하이픈차량번호}]<br/>
                                내 차 현재 시세(추정): {commaNum(form.하이픈중고차가격)}원</p>
                            : <p>차량 조회에 실패하여 시세 정보를 불러오지 못했습니다.<br/>
                                아래 슬라이더를 움직여 수동으로 '내 차 현재 시세'를 설정해주세요.
                            </p>}
                    </TextBox>
                    <h4>‘내 차 현재 시세’는 추정 값으로, 실제와 많이 다를 수 있습니다. bar를 좌우로 이동시켜 차량 시세에 따른 추천 월이용료를 확인해보세요!<br/>
                        추천 월이용료는 차량의 시세와 연식에 따른 감가상각, 보험료, 자동차세, 정비비를 고려하여 산출됩니다.
                    </h4>
                </div>
                <div>
                    <h2 style={{fontSize: '18px', minWidth: '115px', marginBottom: '10px'}}>내 차 현재 시세</h2>
                    <StyledSlider
                        className="horizontal-slider"
                        thumbClassName="--circle"
                        trackClassName="--track"
                        defaultValue={2500}
                        step={100}
                        min={300}
                        max={15000}
                        value={form.차량시세}
                        onChange={(v) => onChange({id: '차량시세', value: v})}
                        renderThumb={(props, state) => <div {...props}>{commaNum(state.valueNow)}만원</div>}
                    />
                </div>
                {/*<div style={{display: 'flex', justifyContent: 'center'}}>*/}
                {/*    <MySearchBtn onClick={onClickPaste}>*/}
                {/*        /!*<img src="/vehicle/search.svg"/>*!/*/}
                {/*        🔍 클릭해서 추천 수익 그대로 입력하기</MySearchBtn>*/}
                {/*</div>*/}

                <Separator style={{margin: '4px 0'}}/>

                <div style={{marginTop: '10px', gap: '32px', display: 'flex', flexDirection: 'column'}}>
                    <div style={{gap: '16px', display: 'flex', flexDirection: 'column'}}>
                        <h2 style={{fontSize: '18px', minWidth: '115px', margin: '6px 0 6px'}}>매칭유형 선택 (복수선택 가능)</h2>

                        <div style={{paddingTop: '10px'}}>
                            <RadioComponent id="later" form={form} onChange={onChange} values={[
                                {id: 'NOW', label: '지금 정하기', description: '매칭유형을 선택하고 추천금액을 참고하여 월이용료를 직접 입력해주세요.'},
                                {id: 'LATER', label: '나중에 정하기', description: '매니저와 상담 후 결정합니다. 선택 시 예비호스트로 등록됩니다.'},
                            ]}/>
                        </div>
                        <Separator style={{margin: '16px 0'}}/>
                        {/*<h3>게스트의 연령이 30세 미만일 경우, 보험료 인상액을 고려하여 월이용료를 추가로 청구할 수 있습니다. 매칭 조율 시 담당 매니저에게 알려주세요.</h3>*/}
                        <UseType later={form.later === 'LATER'} checked={checked('PRIORITY_GUEST')} onClick={() => form.later !== 'LATER' && toggle('PRIORITY_GUEST')}>
                            <Flexbox>
                                <div style={{display: 'flex', gap: '10px', cursor: 'pointer'}}>
                                    <img src={`/vehicle/check_${form.later === 'LATER'? 'disabled': checked('PRIORITY_GUEST')}.svg`} />
                                    <h2 style={{minWidth: '90px'}}>게스트우선형</h2>
                                </div>
                                <div style={{flexGrow: '1'}} onClick={(e) => e.stopPropagation()}>
                                    <FormComponent id="price_PRIORITY_GUEST" type="tel" unit="원" disabled={form.later === 'LATER' || !checked('PRIORITY_GUEST')} form={checked('PRIORITY_GUEST') ? form: {price_PRIORITY_GUEST: ''}} onChange={onChange} />
                                </div>
                            </Flexbox>
                            <h3 style={{textAlign: 'right', color: '#4d4df6', lineHeight: '120%', marginRight: '16px'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (!checked('PRIORITY_GUEST')) {
                                        toggle('PRIORITY_GUEST');
                                    }
                                    onChange({id: 'price_PRIORITY_GUEST', value: form.예상월분담액_PRIORITY_GUEST});
                                }}
                            >추천 월이용료 : {commaNum(form.예상월분담액_PRIORITY_GUEST)}원</h3>
                            <h3 style={{color: '#333333', whiteSpace: 'pre-wrap'}}>
                                {getDescriptionSimple('PRIORITY_GUEST')}
                            </h3>
                            <h4 style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={(e) => {
                                e.stopPropagation();
                                setUseType("PRIORITY_GUEST");
                                setDetailOpen(true);
                            }}>자세히 보기 {'>'}</h4>
                        </UseType>
                        <UseType later={form.later === 'LATER'} checked={checked('DAY_SELECT')} onClick={() => form.later !== 'LATER' && toggle('DAY_SELECT')}>
                            <Flexbox>
                                <div style={{display: 'flex', gap: '10px', cursor: 'pointer'}}>
                                    <img src={`/vehicle/check_${form.later === 'LATER'? 'disabled': checked('DAY_SELECT')}.svg`} />
                                    <h2 style={{minWidth: '90px'}}>요일지정형</h2>
                                </div>
                                <div style={{flexGrow: '1'}} onClick={(e) => e.stopPropagation()}>
                                    <FormComponent id="price_DAY_SELECT" type="tel" unit="원" disabled={!checked('DAY_SELECT')} form={checked('DAY_SELECT') ? form: {price_DAY_SELECT: ''}} onChange={onChange} />
                                </div>
                            </Flexbox>
                            <h3 style={{textAlign: 'right', color: '#4d4df6', lineHeight: '120%', marginRight: '16px'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (!checked('DAY_SELECT')) {
                                        toggle('DAY_SELECT');
                                    }
                                    onChange({id: 'price_DAY_SELECT', value: form.예상월분담액_DAY_SELECT});
                                }}
                            >추천 월이용료 : {commaNum(form.예상월분담액_DAY_SELECT)}원</h3>
                            <h3 style={{color: '#333333', whiteSpace: 'pre-wrap'}}>
                                <span style={{cursor: 'pointer', textDecoration: 'underline', color: '#44798f', fontSize: '18px'}} onClick={() => setUseTypeOpen(true)}>[이용 요일 지정하기]</span><br/>
                                {getDescriptionSimple('DAY_SELECT')}
                            </h3>
                            <h4 style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={(e) => {
                                e.stopPropagation();
                                setUseType("DAY_SELECT");
                                setDetailOpen(true);
                            }}>자세히 보기 {'>'}</h4>
                        </UseType>

                        <UseType later={form.later === 'LATER'} checked={checked('PRIORITY_HOST')} onClick={() => form.later !== 'LATER' && toggle('PRIORITY_HOST')}>
                            <Flexbox>
                                <div style={{display: 'flex', gap: '10px', cursor: 'pointer'}}>
                                    <img src={`/vehicle/check_${form.later === 'LATER'? 'disabled': checked('PRIORITY_HOST')}.svg`} />
                                    <h2 style={{minWidth: '90px'}}>호스트우선형</h2>
                                </div>
                                <div style={{flexGrow: '1'}} onClick={(e) => e.stopPropagation()}>
                                    <FormComponent id="price_PRIORITY_HOST" type="tel" unit="원" disabled={!checked('PRIORITY_HOST')} form={checked('PRIORITY_HOST') ? form: {price_PRIORITY_HOST: ''}} onChange={onChange} />
                                </div>
                            </Flexbox>
                            <h3 style={{textAlign: 'right', color: '#4d4df6', lineHeight: '120%', marginRight: '16px'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (!checked('PRIORITY_HOST')) {
                                        toggle('PRIORITY_HOST');
                                    }
                                    onChange({id: 'price_PRIORITY_HOST', value: form.예상월분담액_PRIORITY_HOST});
                                }}
                            >추천 월이용료 : {commaNum(form.예상월분담액_PRIORITY_HOST)}원</h3>

                            <h3 style={{color: '#333333', whiteSpace: 'pre-wrap'}}>
                                {getDescriptionSimple('PRIORITY_HOST')}
                            </h3>
                            <h4 style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={(e) => {
                                e.stopPropagation();
                                setUseType("PRIORITY_HOST");
                                setDetailOpen(true);
                            }}>자세히 보기 {'>'}</h4>
                        </UseType>
                    </div>
                    <div style={{marginTop: '10px', gap: '16px', display: 'flex', flexDirection: 'column'}}>
                        {/*<Flexbox>*/}
                        {/*<h2 style={{fontSize: '18px', minWidth: '115px'}}>거리당수익</h2>*/}
                        <div>
                            <Flexbox>
                                <h2 style={{minWidth: '90px'}}>거리당 주유비</h2>
                                <div style={{flexGrow: '1'}}>
                                    <FormComponent id="doorivePriceKm" type="tel" unit="원 / km" disabled={form.later === 'LATER'} form={form} onChange={onChange} />
                                </div>
                            </Flexbox>
                            <h3 style={{textAlign: 'right', color: '#4d4df6', lineHeight: '120%', marginRight: '16px', cursor: 'pointer'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onChange({id: "doorivePriceKm", value: form.예상거리당주유비});
                                }}
                            >추천 거리당주유비 : {commaNum(form.예상거리당주유비)}원</h3>
                            <h3>게스트가 주행한 거리만큼 호스트에게 지급하는 금액입니다. (두리플러그를 통해 자동측정) 대신, 호스트는 본인의 계산으로 직접 주유하면서 게스트 운행에 문제가 없도록 연료를 항상 충분한 상태로 유지해야합니다. (게스트우선형은 반대)</h3>
                            <h3>차량의 연비, 주변 주유소 시세 등을 고려하여 실제 주유비보다 약간 여유롭게 설정하는 것을 권장드립니다.</h3>
                            {/*<h3>게스트가 사용한 연료의 대가로 호스트에게 지급하는 금액입니다. 대신, 호스트는 본인의 계산으로 항상 연료를 충분한 상태로 유지하여 게스트의 운행에 차질이 없도록 해야합니다. 단, ‘게스트 우선형’의 경우 반대로 호스트가 게스트에게 지급하며 게스트는 본인의 계산으로 항상 연료를 충분한 상태로 유지하여야합니다.</h3>*/}
                        </div>
                        {/*{form.useType !== 'PRIORITY_GUEST' &&*/}
                        {/*    <h3>게스트가 매월 주행한 거리만큼 지급하는 금액으로, 주유, 소모품교체, 정비, 세차 등 다양한 유지비가 모두 포함되어 있습니다.<br/>*/}
                        {/*        특히, 게스트가 결제하는 주유비는 호스트의 수익금 계좌에서 차감된다는 점을 고려하여 금액을 여유롭게 입력해주세요.</h3>}*/}
                        {/*{form.useType === 'PRIORITY_GUEST' &&*/}
                        {/*    <>*/}
                        {/*        <h3>게스트가 매월 주행한 거리만큼 지급하는 금액으로, 소모품교체, 정비 등 주유비를 제외한 다양한 유지비가 모두 포함되어 있습니다. (주유비는 게스트 본인 부담)</h3>*/}
                        {/*    </>*/}
                        {/*}*/}
                    </div>
                    {/*<div>*/}
                    {/*    <Flexbox>*/}
                    {/*        <h2 style={{minWidth: '115px', whiteSpace: 'nowrap'}}>자동차세분담수익</h2>*/}
                    {/*        <div style={{flexGrow: '1', margin: '6px 0'}} onClick={() => {*/}
                    {/*            // setInsuranceOpen(true)*/}
                    {/*        }}>*/}
                    {/*            <FormComponent id="예상세금" disabled unit="원 / 월" form={form} onChange={() => {}} />*/}
                    {/*        </div>*/}
                    {/*    </Flexbox>*/}
                    {/*    <h3>게스트는 전체 자동차세의 절반을 부담합니다. (연간 총자동차세를 12개월로 나누어 매월 지급)</h3>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <Flexbox>*/}
                    {/*        <h2 style={{minWidth: '115px', margin: '6px 0', whiteSpace: 'nowrap'}}>보험료분담수익</h2>*/}
                    {/*        <div style={{flexGrow: '1'}} onClick={() => {*/}
                    {/*            // setInsuranceOpen(true)*/}
                    {/*        }}>*/}
                    {/*            <FormComponent id="doorivePriceInsurance" type="tel" unit="원 / 월" form={form} onChange={onChange} />*/}
                    {/*        </div>*/}
                    {/*    </Flexbox>*/}
                    {/*    <h3>*/}
                    {/*        게스트가 자동차보험의 적용을 받는 대가로 납부하는 보험료분담액입니다 (게스트 연령 30세 이상 기준)<br/>*/}
                    {/*        게스트의 연령이 30세 미만일 경우 보험료 인상폭이 클 수 있으므로, 매칭 시 개별 협의를 권장드립니다.*/}
                    {/*        /!*게스트가 자동차보험의 적용을 받는 대가로 납부하는 보험료분담액입니다.<br/>*!/*/}
                    {/*        /!*게스트의 연령이 낮을수록 보험료분담액이 올라갑니다.*!/*/}
                    {/*    </h3>*/}
                    {/*</div>*/}
                    <div>
                        <Flexbox>
                            <h2 style={{minWidth: '90px', margin: '6px 0'}}>보증금</h2>
                            <div style={{flexGrow: '1', position: 'relative'}} onClick={() => setOpen(true)}>
                                <FormComponent id="보증금" disabled placeholder="월이용료의 3배" value={""} form={form} onChange={onChange} />
                                <RightLabel>자세히 보기{'>'}</RightLabel>
                            </div>
                        </Flexbox>
                    </div>
                </div>
                {/*<div>*/}
                {/*    <ImgSlider*/}
                {/*        className="horizontal-slider"*/}
                {/*        thumbClassName="--circle"*/}
                {/*        trackClassName="--track"*/}
                {/*        defaultValue={1000}*/}
                {/*        step={100}*/}
                {/*        min={0}*/}
                {/*        max={5000}*/}
                {/*        onChange={(v) => onChange({id: '게스트주행거리', value: v})}*/}
                {/*        renderThumb={(props, state) => <div {...props}><img style={{width: '71px'}} src="/vehicle/img_car.svg"></img></div> }*/}
                {/*    />*/}
                {/*    <div style={{marginTop:'8px', textAlign: 'center'}}>*/}
                {/*        게스트 주행거리: {commaNum(form.게스트주행거리)}km/월<br/>*/}
                {/*        <h4 className="gray">(좌우로 이동시켜보세요!)</h4>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <TextBox style={{textAlign: 'center', fontSize: '16px'}}>*/}
                {/*        총 예상 수익: {commaNum(Number(form.doorivePriceMonth) + Number(form.예상세금) + form.게스트주행거리 * Number(form.doorivePriceKm))}원 / 월*/}
                {/*    </TextBox>*/}
                {/*    <h4>게스트의 주유비 등 유지비와 관리수수료(7%)가 포함된 금액입니다.</h4>*/}
                {/*</div>*/}
            </div>
        </Wrap>
        <BottomDrawer open={open} onClose={() => setOpen(false)}>
            <h1>보증금 안내</h1>
            <p style={{marginTop: '16px'}}>보증금은 3개월치 월이용료 상당의 금액으로 책정되며 담보의 역할을 합니다. 금액은 호스트가 임의로 변경할 수 없으며, 공유기간 동안 두리카에서 보관합니다.</p>
            <PositiveBtn style={{marginTop: '24px', marginBottom: '14px'}} onClick={() => setOpen(false)}>확인</PositiveBtn>
        </BottomDrawer>
        <BottomDrawer open={useTypeOpen} onClose={() => setUseTypeOpen(false)}>
            <UseTypeDrawer id="daySelect" form={form} onChange={onChange} onClose={() => setUseTypeOpen(false)}/>
            <PositiveBtn style={{marginTop: '24px', marginBottom: '14px'}} onClick={() => setUseTypeOpen(false)}>확인</PositiveBtn>
        </BottomDrawer>
        <SafetyDialog useType={useType} open={detailOpen} onClose={() => setDetailOpen(false)} />
        <InsuranceDialog form={form} onChange={onChange} open={insuranceOpen} onClose={() => setInsuranceOpen(false)} />
    </>
}

const InsuranceDialog = ({open, onClose, form, onChange}) => {

    const getYearSelect = () => {
        const now = moment.tz('Asia/Seoul');
        let i = now.year();
        const result = [];
        while (i >= 2000) {
            result.push(i);
            i -= 1;
        }
        return result.map((i) => ({id: String(i), label: String(i)}));
    }

    return <Dialog open={open} onClose={onClose} fullWidth={true}>
        <DrawerWrap>
            <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <p>보험·세금분담수익은 보험료, 연식, 배기량을 통해 산정되므로 직접 수정이 불가능합니다.<br/>수정 희망 시 아래 값을 변경해주세요.</p>
                <SelectComponent id="year" title="연식" form={form} onChange={onChange} values={getYearSelect()}/>
                <div>
                    <FormComponent id="displacement" type="tel" title="배기량" unit="cc" placeholder="1998" form={form} onChange={onChange} />
                    <p className="big">전기자동차의 경우는 0을 입력해주세요</p>
                </div>
            </div>
            <PositiveBtn style={{marginTop: '24px'}} onClick={onClose}>확인</PositiveBtn>
        </DrawerWrap>
    </Dialog>
}

const RightLabel = styled.span`
  color: var(--gray-700, #939393);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-decoration-line: underline;
  position: absolute;
  top: 50%;
  right: 12px; /* 오른쪽 패딩 값 */
  transform: translateY(-50%);  /* 세로 중앙 정렬 */
  user-select: none;
`

const MySearchBtn = styled(SearchBtn)`
  color: var(--gray-scale-white, #FFF);
  background: #33CCC1;
  border: none;
`
const Separator = styled.div`
  height: 1px;
  background: var(--gray-scale-gray-300, #EFEFEF);
`

const Flexbox = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const Wrap = styled.div`
  padding: 32px 20px;
  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  h2 {
    color: var(--gray-scale-black, #212121);

    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  h3 {
    margin: 4px 0;
    color: var(--gray-700, #939393);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
  }

  h4 {
    margin-top: 4px;
    color: #A9A9A9;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }


  .underline {
    text-decoration-line: underline;
    cursor: pointer;
  }

  .mint {
    color: var(--mint, #33CCC1);
  }

  > .forms {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`

const UseType = styled.div`
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--gray-scale-gray-300, #EFEFEF);
  cursor: pointer;
  ${(props) => props.checked && css`
    color: var(--gray-scale-black, #212121);
    font-weight: 600;
    border: 1px solid var(--mint, #33CCC1);
    background: rgba(51, 204, 193, 0.10);
  `}
  ${(props) => props.disabled && css`
    cursor: not-allowed;
  `}
  ${(props) => props.later && css`
    cursor: not-allowed;
    background: rgba(49, 49, 49, 0.1);
    border: 1px solid rgba(49, 49, 49, 0.1);
  `}
`

const TextBox = styled.div`
  margin-top: 4px;
  margin-bottom: 8px;
  padding: 16px 12px;
  border-radius: 8px;
  background: var(--gray-scale-gray-200, #F5F5F5);

  color: var(--gray-scale-black, #212121);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  .thin {
    font-weight: 400;
  }
  .link {
    font-weight: 600;
    text-decoration-line: underline;
    cursor: pointer;
  }
`

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 32px;
  overflow: hidden;

  .--circle {
    display: flex;
    align-items: center;
    height: 32px;
    border-radius: 100px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 700;
    font-size: 16px;
    background-color: black;
    color: white;
    z-index: unset !important;
    user-select: none;
    white-space: nowrap;
    //top: 0;
  }

  .--track {
    top: 14px;
    width: 100%;
    height: 3px;
    background-color: #000000;
    border-radius: 3px;
  }

  .--track-1 {
    top: 14px;
    width: 100%;
    height: 3px;
    background-color: #E8E8E8;
    border-radius: 3px;
  }
`;

const ImgSlider = styled(ReactSlider)`
  width: 100%;
  height: 32px;
  overflow: hidden;

  .--circle {
    display: flex;
    align-items: center;
    height: 32px;
    font-weight: 700;
    font-size: 16px;
    z-index: unset !important;
    user-select: none;
    white-space: nowrap;
  }

  .--track {
    top: 14px;
    width: 100%;
    height: 3px;
    background-color: #000000;
    border-radius: 3px;
  }

  .--track-1 {
    top: 14px;
    width: 100%;
    height: 3px;
    background-color: #E8E8E8;
    border-radius: 3px;
  }
`;

export default PriceSet;
