import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useCallback, useEffect, useRef, useState} from "react";
import {filter, find, get, isEmpty, isEqual, isNil} from "lodash";
import {convertDooricar, getLoginLink} from "../../toolbox/format";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import moment from "moment-timezone";
import 'moment/locale/ko';
import queryString from "query-string";
import {useQuery} from "@apollo/client";
import {GET_USER} from "../../query/userQuery";
import {GET_DOORI_VEHICLE} from "../../query/vehicleQuery";
import {flatEntity} from "../../toolbox/query";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const ChatCreatePage = () => {
    const location = useLocation();

    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const userId = parsed.userId;
    const vehicleId = parsed.vehicleId;

    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [vehicle, setVehicle] = useState(null);

    const {data: userData} = useQuery(GET_USER, {variables: {id: userId}, skip: isNil(userId), fetchPolicy: 'cache-first'});
    const {data: vehicleData} = useQuery(GET_DOORI_VEHICLE, {variables: {id: vehicleId}, skip: isNil(vehicleId)});

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, [tokenInfo]);

    useEffect(() => {
        if (userData) {
            let user = get(userData, "usersPermissionsUser.data", null);
            user = flatEntity(user);
            setUser(user);
        }
    }, [userData]);

    useEffect(() => {
        if (vehicleData) {
            const payload = flatEntity(get(vehicleData, "vehicle.data", null));
            if (payload) {
                let v = convertDooricar(payload);
                if (v) {
                    setVehicle(v);
                } else {
                    alert('이미 매칭이 되었거나 비활성화된 차량입니다.');
                    navigate(-1);
                }
            } else {
                alert('이미 매칭이 되었거나 비활성화된 차량입니다.');
                navigate(-1);
            }
        }
    }, [vehicleData]);



    const getChatRoomLink = () => {
        if (userId) {
            return "/user/" + userId;
        } else if (vehicleId) {
            return "/vehicle/" + vehicleId;
        }
        return null;
    }


    const getChatRoomTitle = () => {
        if (user) {
            return <>
                <ProfileImage src={user?.profile_image?.url ?? '/layout/char_doori.svg'} />
                <span>{user?.nickname}</span>
            </>
        }
        if (vehicle && !userId) {
            return <>
                <ProfileImage src={vehicle?.hostUser?.profile_image?.url ?? '/layout/char_doori.svg'} />
                <span>{vehicle.brand} {vehicle.model} 호스트 ({vehicle.numberPlate})</span>
            </>
        }
        return null;
    }

    useEffect(() => {
        if (userId || vehicleId) {
            axios.post(SERVER_ADDRESS + '/api/chat-rooms/findMyRooms', {}).then((res) => {
                if (res.data) {
                    for (let room of res.data) {
                        const otherUsers = filter(room.users, u => String(u.id) !== String(tokenInfo.id));
                        if (room.users.length !== 2) {
                            continue;
                        }

                        if (vehicleId && userId) {
                            if (String(vehicleId) === String(room?.contract?.vehicle?.id)) {
                                if (otherUsers.length === 1 && String(userId) === String(otherUsers[0].id)) {
                                    navigate('/my/chat/' + room.id);
                                }
                            }
                        } else if (vehicleId) {
                            if (String(vehicleId) === String(room?.contract?.vehicle?.id)) {
                                if (otherUsers.length === 1 && String(room?.contract?.users_permissions_user?.id) === String(otherUsers[0].id)) {
                                    navigate('/my/chat/' + room.id);
                                }
                            }
                        } else if (userId) {
                            if (!room?.contract) {
                                if (otherUsers.length === 1 && String(userId) === String(otherUsers[0].id)) {
                                    navigate('/my/chat/' + room.id);
                                }
                            }
                        }
                    }
                }
            });
        }
    }, [userId, vehicleId]);

    const handleSend = (message) => {
        if (userId && !user) {
            return;
        }
        if (vehicleId && !vehicle) {
            return;
        }

        const targetInfo = {
            users: [tokenInfo.id],
            contract: null,
        }

        if (userId) {
            targetInfo.users.push(userId);
        } else if (vehicleId) {
            targetInfo.users.push(vehicle.hostUser.id);
        }

        if (vehicleId) {
            targetInfo.contract = vehicle.hostContract.id;
        }

        axios.post(SERVER_ADDRESS + '/api/chat-rooms/createRoom', {
            ...targetInfo,
            message: {
                type: 'text',
                content: message,
            },
        }).then((res) => {
            navigate('/my/chat/' + res.data.id);
        });
    }

    return <div>
        {getChatRoomLink() && <Link to={getChatRoomLink()}>
            <div style={{fontSize: '20px', padding: '16px 12px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px'}}>
                {getChatRoomTitle()}
            </div>
        </Link>}
        {!getChatRoomLink() && <div style={{fontSize: '20px', padding: '16px 12px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px'}}>
            {getChatRoomTitle()}
        </div>}
        <Wrap />
        <ChatInput onSend={handleSend} />
    </div>
}

const Wrap = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: calc(100vh - 300px);
  overflow-y: auto;
  align-self: stretch;
`

const ChatInputContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
  align-self: stretch;
`;

const TextInput = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  align-items: center;
  padding-left: 12px;
  height: 45px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #888;
  }
  
  .image {
    padding: 10px 10px 6px;
    height: 100%;
    flex-grow: 1;
    cursor: pointer;
  }
  
  textarea {
    font-size: 14px;
    padding: 10px 10px 10px 0;
    width: 100%;
    resize: none;
    overflow-y: auto;
    border: none;
    max-height: 26px;
    &:focus {
      outline: none;
      border-color: #888;
    }
  }
`;

const SendButton = styled.button`
  margin-left: 10px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ChatInput = ({ onSend }) => {
    const [message, setMessage] = useState('');
    const textareaRef = useRef(null);
    const [isComposition, setIsComposition] = useState(false);

    const handleChange = (e) => {
        setMessage(e.target.value);
    };

    const handleKeyDown = (e) => {
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        if (!isMobile && e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const handleInput = () => {
        textareaRef.current.style.height = 'auto'; // 초기화
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight - 18}px`;
    };

    const handleSend = () => {
        if (message.trim() && !isComposition) {
            onSend(message.trim());
            setMessage('');
        }
    };

    return (
        <ChatInputContainer>
            <TextInput>
                <textarea
                    ref={textareaRef}
                    value={message}
                    onChange={handleChange}
                    onInput={handleInput}
                    onCompositionStart={() => setIsComposition(true)}
                    onCompositionEnd={() => setIsComposition(false)}
                    rows={1}
                    onKeyDown={handleKeyDown}
                    placeholder="메시지를 입력하세요..." />
            </TextInput>
            <SendButton onClick={handleSend}>전송</SendButton>
        </ChatInputContainer>
    );
};


const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;
