import styled, { css } from "styled-components";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import { useState } from "react";

export const MB8 = styled.div`
    margin-bottom: 8px;
`;

export const MB16 = styled.div`
    margin-bottom: 16px;
`;

export const MB32 = styled.div`
    margin-bottom: 32px;
`;

export const HelpBtn = ({ title, contents }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            {/*<img id="drawerBtn" onClick={(e) => {*/}
            {/*    e.preventDefault();*/}
            {/*    e.stopPropagation();*/}
            {/*    setOpen(true)*/}
            {/*}} style={{width: '20px', height: '20px', cursor: 'pointer', filter: `invert(${white? '1': '0'})`}} src={"/help-btn.png"} />*/}
            <HelpIcon
                style={{ cursor: "pointer" }}
                fontSize="inherit"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(true);
                }}
            />
            <Dialog
                open={open}
                onClose={(e) => {
                    setOpen(false);
                }}
                fullWidth={true}
            >
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    style={{
                        padding: "32px",
                        whiteSpace: "pre-wrap",
                        wordBreak: "keep-all",
                        lineHeight: "140%",
                    }}
                >
                    {title && <h3 style={{ marginBottom: "8px" }}>{title}</h3>}
                    {contents}
                </div>
            </Dialog>
        </>
    );
};

const _DooriveModal = ({
    className,
    title,
    actions,
    children,
    open,
    onClose,
}) => {
    return (
        <div className={className}>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle sx={{ paddingRight: "56px", fontSize: "18px" }}>
                    {title}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#888888",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ paddingTop: "16px" }}>
                    <>{children}</>
                </DialogContent>
                <DialogActions>
                    {actions &&
                        actions.map((action) => (
                            <Button onClick={action.onClick}>
                                {action.title}
                            </Button>
                        ))}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export const DooriveModal = styled(_DooriveModal)``;
