import { gql } from "@apollo/client";

export const VEHICLE_QUERY = `
  id
  attributes {
    owned
    brand
    model
    trim
    year
    mileage
    options
    fuelType
    color
    numberPlate
    transmissionType
    displacement
    kmCharge
    overCharge
    youngCharge
    priceDuo
    priceSolo
    bakchaAdditionalInfo
    doorive
    visible
    currentValue
    eventPrice
    draftVehicle
    
    thumbnail {
      data {
        id
        attributes {
          width
          height
          url
        }
      }
    }
  
    pictures {
      data {
        id
        attributes {
          width
          height
          url
        }
      }
    }
  
  
  
  likes {
    data {
      id
      attributes {
        createdAt
        user {
          data {
            id
            attributes {
              address
              lng
              lat
              introduce
              mapMarker
              nickname
              phone
              profile_image {
                data {
                  id
                  attributes {
                    url
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  
  contracts {
    data {
      id
      attributes {
        address
        longitude
        latitude
        contractType
        startDate
        endDate
        status
        harucar
        dooricar
        dooriveDescription
        dooriveTitle
        doorivePriceKm
        doorivePriceMonth
        doorivePriceInsurance
        doorivePriceTax
        doorivePriceOil
        useType
        useTypes
        useTypeDetail
        daySelect
        matchingDetail
        updateHistory
        openChatLink
        isGhost
        users_permissions_user {
          data {
            id
            attributes {
              nickname
              address
              phone
              profile_image {
                data {
                  id
                  attributes {
                    url
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_DOORI_VEHICLES = gql`
query getVehicles {
  vehicles(filters: {visible: {eq: true}, draftVehicle: {eq: false}}) {
    data{
      ${VEHICLE_QUERY}
    }
  }
}
`;

export const GET_DOORI_VEHICLE = gql`
query getVehicle($id: ID!) {
  vehicle(id: $id) {
    data {
      ${VEHICLE_QUERY}
    }
  }
}
`;

export const GET_USED_VEHICLE = gql`
    query getUsedVehicle($id: ID!) {
        usedVehicle(id: $id) {
            data {
                id
                attributes {
                    brand
                    model
                    year
                    options
                    fuelType
                    numberPlate
                    longitude
                    latitude
                    address
                    title
                    hyphen
                    description
                    priceMonth
                    priceKm
                    youngCharge
                    additional
                    submit
                    useType
                    useTypeDetail
                    contract {
                        data {
                            id
                        }
                    }
                    certificate {
                        data {
                            id
                            attributes {
                                url
                                width
                                height
                            }
                        }
                    }
                    pictures {
                        data {
                            id
                            attributes {
                                url
                                width
                                height
                            }
                        }
                    }
                    thumbnail {
                        data {
                            id
                            attributes {
                                url
                                width
                                height
                            }
                        }
                    }
                    user {
                        data {
                            id
                            attributes {
                                realname
                                nickname
                                address
                                phone
                                profile_image {
                                    data {
                                        id
                                        attributes {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_MATCHING_REQUEST = gql`
query getMatchingRequest($id: ID!) {
  matchingRequest(id: $id) {
    data {
      id
      attributes {
        thread_ts
        detail
        status
        receiver {
          data {
            id
            attributes {
              realname
              phone
              nickname
              address
              residenceNumber
              email2
              accountNumber
              licenseCard {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        sender {
          data {
            id
            attributes {
              realname
              phone
              nickname
              address
              residenceNumber
              email2
              accountNumber
              licenseCard {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        contract {
          data {
            id
            attributes {
              insuranceInfo
              ownershipInfo
              vehicle {
                data {
                  ${VEHICLE_QUERY}
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const UPDATE_VEHICLE = gql`
    mutation updateVehicle($id: ID!, $data: VehicleInput!) {
        updateVehicle(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;
export const UPDATE_CONTRACT = gql`
    mutation updateContract($id: ID!, $data: ContractInput!) {
        updateContract(id: $id, data: $data) {
            data {
                id
                attributes {
                    updatedAt
                }
            }
        }
    }
`;

export const CREATE_VEHICLE = gql`
    mutation createVehicle($data: VehicleInput!) {
        createVehicle(data: $data) {
            data {
                id
            }
        }
    }
`;
export const CREATE_CONTRACT = gql`
    mutation createContract($data: ContractInput!) {
        createContract(data: $data) {
            data {
                id
                attributes {
                    updatedAt
                }
            }
        }
    }
`;
