import styled from "styled-components";
import {
    FormComponent,
    RadioComponent,
    RadioComponentSimple,
} from "../VehicleAdd2/FormComponent";
import { VehicleUseInfoSection3 } from "../component/VehicleUseInfoSection";
import moment from "moment-timezone";
import { commaNum, getPricePlan } from "../../../toolbox/format";
import { useEffect, useMemo } from "react";
import { find } from "lodash";

const MatchInfo = ({ form, onChange, vehicle }) => {
    // useEffect(() => {
    //     if (form["시작 희망일"]) {
    //         let endDates = getEndDates(form["시작 희망일"]);
    //         if (endDates.length === 1) {
    //             onChange({id: '예상 이용기간', value: endDates[0].id});
    //         }
    //     }
    // }, [form["6개월 약정 여부"], form["시작 희망일"]]);

    // const getEndDates = (startDate) => {
    //     if (!startDate) {
    //         return [];
    //     }
    //     const start = moment.tz(startDate, 'Asia/Seoul');
    //     const i = start.clone();
    //     const result = [];
    //     if (start.date() !== 1) {
    //         i.add(1, 'month');
    //     }

    //     if (vehicle.eventPrice !== false) {
    //         if (form["6개월 약정 여부"] === '적용') {
    //             i.add(5, 'month');
    //             for (let j = 0; j < 7; j++) {
    //                 let txt = i.endOf('month').format('YYYY년 M월 DD일');
    //                 result.push({
    //                     id: i.format('YYYY-MM-DD'),
    //                     label: `${txt} (${6 + j}개월)`,
    //                 });
    //                 i.add(1, 'month');
    //             }
    //         } else {
    //             for (let j = 0; j < 5; j++) {
    //                 let txt = i.endOf('month').format('YYYY년 M월 DD일');
    //                 result.push({
    //                     id: i.format('YYYY-MM-DD'),
    //                     label: `${txt} (${1 + j}개월)`,
    //                 });
    //                 i.add(1, 'month');
    //             }
    //         }
    //     } else {
    //         for (let j = 0; j < 12; j++) {
    //             let txt = i.endOf('month').format('YYYY년 M월 DD일');
    //             result.push({
    //                 id: i.format('YYYY-MM-DD'),
    //                 label: `${txt} (${1 + j}개월)`,
    //             });
    //             i.add(1, 'month');
    //         }
    //     }
    //     return result;
    // }

    const priceTxt = useMemo(() => {
        const useType = find(
            vehicle.useTypes,
            (u) => u.id === form["매칭유형"]
        );
        if (!useType) {
            return "매칭 유형을 선택해주세요";
        }
        const isEvent =
            vehicle.eventPrice !== false && form["6개월 약정 여부"] === "적용";
        let originPrice = useType.price;
        if (isEvent) {
            originPrice = originPrice * 0.9;
        }
        const deposit = originPrice * 3;
        const plan = getPricePlan({
            isSocarEvent: false,
            originMonthlyCharge: originPrice,
            startDate: form["시작 희망일"],
            // endDate: form["예상 이용기간"],
        });

        let result = [];
        for (let month of plan) {
            let paymentDate = moment.tz(month.paymentDate, "Asia/Seoul");
            let startDate = moment.tz(month.startDate, "Asia/Seoul");
            let endDate = moment.tz(month.endDate, "Asia/Seoul");
            let charge = month.charge;
            let monthTxt = [
                `${paymentDate.month() + 1}월 결제예정금액`,
                `${startDate.month() + 1}월 이용료: ${commaNum(
                    charge
                )}원 (${startDate.format("YY. M. D.")} ~ ${endDate.format(
                    "YY. M. D."
                )})`,
            ];

            if (result.length === 0) {
                if (isEvent) {
                    monthTxt = monthTxt.concat([
                        `보증금: ${commaNum(deposit)}원`,
                        "장기계약할인금액: -100,000원",
                        `합계: ${commaNum(charge + deposit - 100000)}원\n`,
                    ]);
                } else {
                    monthTxt = monthTxt.concat([
                        `보증금: ${commaNum(deposit)}원`,
                        `합계: ${commaNum(charge + deposit)}원\n`,
                    ]);
                }
            } else {
                monthTxt = monthTxt.concat([`합계: ${commaNum(charge)}원\n`]);
            }
            result = result.concat(monthTxt);
        }
        result.push(`캐시백: ${commaNum(originPrice * 0.2)}원 (3개월 유지)`);
        result.push("...");
        return result.join("\n");
    }, [form, vehicle]);

    const minContractPeriod = useMemo(() => {
        if (form["시작 희망일"]) {
            const start = moment.tz(
                form["시작 희망일"],
                "YYYY-MM-DD",
                "Asia/Seoul"
            );
            if (start.date() !== 1) {
                return start
                    .add(1, "month")
                    .endOf("month")
                    .format("YYYY-MM-DD");
            } else {
                return start.endOf("month").format("YYYY-MM-DD");
            }
        }
        return null;
    }, [form]);

    return (
        <>
            <Wrap>
                <h1>매칭조건 입력하기</h1>
                <div className="forms">
                    {/* {vehicle.eventPrice !== false && <RadioComponent id="6개월 약정 여부" form={form} onChange={onChange} title="장기 계약 할인" values={[
                    {id: '적용', label: '적용 (6개월 이상 계약)'},{id: '미적용', label: '미적용 (6개월 미만 계약)'},
                ]}/>} */}
                    <div>
                        <div
                            style={{
                                color: "#212121",
                                fontSize: "16px",
                                fontWeight: "600",
                                lineHeight: "24px",
                                marginBottom: "4px",
                            }}
                        >
                            매칭 유형
                        </div>
                        <VehicleUseInfoSection3
                            vehicle={{
                                ...vehicle,
                                eventPrice:
                                    vehicle.eventPrice !== false &&
                                    form["6개월 약정 여부"] === "적용",
                            }}
                            id="매칭유형"
                            form={form}
                            onChange={onChange}
                        />
                    </div>
                    <FormComponent
                        type="date"
                        id="시작 희망일"
                        title="시작일"
                        onChange={onChange}
                        form={form}
                    />
                    <FormComponent
                        disabled
                        id="최소계약기간"
                        form={{
                            최소계약기간: minContractPeriod,
                        }}
                        onChange={() => {}}
                        title="종료일"
                        type="text"
                        description={`해지요청 전까지 매월 자동으로 연장됩니다.`}
                    />
                    {/* <RadioComponent id="예상 이용기간" form={form} onChange={onChange} title="종료일" values={getEndDates(form["시작 희망일"])}/> */}

                    <div>
                        <h3 style={{ marginBottom: "10px" }}>
                            요금 납부 일정표
                        </h3>
                        <TextBox>{priceTxt}</TextBox>
                    </div>
                </div>
            </Wrap>
        </>
    );
};

const TextBox = styled.div`
    padding: 16px 12px;
    border-radius: 8px;
    background: var(--gray-scale-gray-200, #f5f5f5);

    color: var(--gray-scale-gray-700, #939393);
    font-feature-settings: "clig" off, "liga" off;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    white-space: pre-wrap;
`;

const Wrap = styled.div`
    padding: 0 20px 40px;
    > h1 {
        padding: 32px 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    > .forms {
        display: flex;
        flex-direction: column;
        gap: 32px;

        p {
            color: var(--gray-scale-gray-700, #939393);
            font-feature-settings: "clig" off, "liga" off;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            margin: 4px 0;
        }
    }
`;

export default MatchInfo;
